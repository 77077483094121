import React, {ChangeEvent} from 'react';
import './Text.css';

interface TextProps {
    content: string;
    label: string;
    name: string;
    placeholder?: string;
    onChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

const Text: React.FC<TextProps> = ({name, label, content, placeholder, onChange}) => {
    return (
        <div className="text-input">
            <label htmlFor={name}>{label}</label>
            <input
                id={name}
                type="text"
                placeholder={placeholder}
                name={name}
                value={content}
                onChange={onChange}
            />
        </div>
    );
};

export default Text;