import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";

interface ViewContextProps {
    userRole: string;
    is3DView: boolean;
    isOptionVisible: boolean;
    setIs3DView: (is3DView: boolean) => void;
    toggleOptionsVisibility: () => void;
}

const ViewContext = createContext<ViewContextProps | null>(null);

export const ViewProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [userRole, setUserRole] = useState<string>('client');
    const [is3DView, setIs3DView] = useState(true);
    const [isOptionVisible, setIsOptionVisible] = useState(true);
    const toggleOptionsVisibility = () => setIsOptionVisible((prev) => !prev);

    useEffect(() => {
        const ur = document.querySelector('input[name="userRole"]')?.getAttribute('value');
        setUserRole(ur ?? 'client');
    }, []);

    return (
        <ViewContext.Provider
            value={{
                userRole,
                is3DView,
                isOptionVisible,
                setIs3DView,
                toggleOptionsVisibility,
            }}
        >
            {children}
        </ViewContext.Provider>
    );
};

export const useView = (): ViewContextProps => {
    const context = useContext(ViewContext);

    if (!context) {
        throw new Error("useView must be used within a ViewProvider");
    }

    return context;
};