import {Configuration, DataItem, Dimensions, Gutter, Parapet, Roof, Wall} from "src/types/configuration";
import ConfiguratorService from "src/services/configuratorService";

class CalculationService {
    private static instance: CalculationService;
    private configuratorService?: ConfiguratorService;
    private dimensions?: Dimensions;
    private parapet?: Parapet;
    private roof?: Roof;
    private roofOverhang = 0;
    private gutter?: Gutter;
    private walls?: Wall[];
    private storages?: { left: boolean, right: boolean };
    private data: DataItem[] = [];

    constructor(initConfiguration: Configuration, configuratorService: ConfiguratorService) {
        if (CalculationService.instance) {
            return CalculationService.instance;
        }

        CalculationService.instance = this;
        this.configuratorService = configuratorService;
        this.dimensions = initConfiguration.dimensions;
        this.parapet = initConfiguration.parapet;
        this.roof = initConfiguration.roof;
        this.gutter = initConfiguration.gutter;
        this.walls = initConfiguration.walls;
        this.storages = initConfiguration.storages;
    }

    setDimensions(dimensions: Dimensions): void {
        this.dimensions = dimensions;
    }

    setParapet(parapet: Parapet): void {
        this.parapet = parapet;
    }

    setRoof(roof: Roof): void {
        this.roof = roof;
    }

    setGutter(gutter: Gutter): void {
        this.gutter = gutter;
    }

    setWalls(walls: Wall[]): void {
        this.walls = walls;
    }

    setStorages(storages: { left: boolean, right: boolean }): void {
        this.storages = storages;
    }

    formatToCurrency(price: number) {
        return price.toLocaleString('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    calculateTotalPrice(): number {
        return this.data.reduce((acc, item) => acc + item.total, 0);
    };

    getFormatTotalPrice(): string {
        return this.formatToCurrency(this.calculateTotalPrice());
    };

    toMeter = (value: number): number => {
        const meters = value / 1000;

        return parseFloat(meters.toFixed(2));
    };

    toSqMeter(value: number): number {
        const meters = value / 1_000_000;

        return parseFloat(meters.toFixed(2));
    };

    calculateRoofSideLength() {
        const base = this.dimensions?.depth ?? 0;
        const angle = this.dimensions?.angle ?? 40;
        const angleInRadians = (angle * Math.PI) / 180;
        const sideLength = (base + this.roofOverhang) / (2 * Math.cos(angleInRadians));

        return parseFloat(sideLength.toFixed(2));
    };

    calculateRoofSquare() {
        const width = this.dimensions?.width ?? 0;
        const sideLength = this.calculateRoofSideLength();

        return this.toSqMeter(2 * sideLength * (width + this.roofOverhang));
    };

    calculateTriangleArea() {
        const base = this.dimensions?.depth ?? 0;
        const sideLength = this.calculateRoofSideLength();

        return this.toSqMeter((1 / 2) * base * Math.sqrt(4 * sideLength ** 2 - base ** 2));
    };

    getCountVerticalBalks() {
        return this.configuratorService?.getColumnsBalks() ?? 0;
    }

    calculateBetonproer() {
        let quantity = 0;

        if (this.parapet?.enabled) {
            quantity += this.getCountVerticalBalks();
        }

        return quantity;
    };

    calculateConfiguration(data: DataItem[]): DataItem[] {
        const borstweringHeight = 300;
        const width = this.dimensions?.width ?? 0;
        const depth = this.dimensions?.depth ?? 0;
        const height = this.dimensions?.height ?? 0;

        this.data = data.map((item) => {
            if (item.name === 'betonproer') {
                const quantity = this.calculateBetonproer();

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'staanders') {
                const quantity = this.getCountVerticalBalks();

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'ringbalk') {
                let quantity = ((this.dimensions?.width ?? 0) + (this.dimensions?.depth ?? 0)) * 2; // perimeter
                quantity += this.calculateRoofSideLength() * this.getCountVerticalBalks(); // roof balks

                this.walls?.forEach((wall) => {
                    if (wall.inner) {
                        quantity += depth;
                    }
                });

                quantity = this.toMeter(quantity);

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'gordingbalken') {
                let quantity = 0;

                if (this.roof?.type === 'isolated plates') {
                    quantity = this.toMeter(4 * width);

                    if (depth >= 4400) {
                        quantity = this.toMeter(6 * width);
                    }
                }

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'sporen') {
                let quantity = 0;

                if (this.roof?.type === 'tiles' || this.roof?.type === 'red tiles') {
                    if (width >= 19300) {
                        quantity = 35;
                    } else if (width >= 17100) {
                        quantity = 30;
                    } else if (width >= 15500) {
                        quantity = 28;
                    } else if (width >= 13100) {
                        quantity = 24;
                    } else if (width >= 12900) {
                        quantity = 20;
                    } else if (width >= 11700) {
                        quantity = 21;
                    } else if (width >= 9800) {
                        quantity = 18;
                    } else if (width >= 8700) {
                        quantity = 15;
                    } else if (width >= 7900) {
                        quantity = 14;
                    } else if (width >= 6700) {
                        quantity = 12;
                    } else if (width >= 5500) {
                        quantity = 10;
                    } else if (width >= 4500) {
                        quantity = 8;
                    } else if (width >= 4100) {
                        quantity = 7;
                    } else if (width >= 3500) {
                        quantity = 6;
                    } else {
                        quantity = 5;
                    }

                    quantity *= this.calculateRoofSideLength();
                    quantity = this.toMeter(quantity);
                }

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'boeideel') {
                let quantity = (width + depth) * 2; // perimeter
                quantity += this.calculateRoofSideLength() * 4; // roof
                quantity = this.toMeter(quantity);

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            // carcas wall and gable
            if (item.name === 'wandregels') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    const tempWidth = this.configuratorService?.getWallWidth(wall) ?? 0;

                    if (tempWidth >= 4000) {
                        quantity += 8;
                    } else if (tempWidth >= 3300) {
                        quantity += 7;
                    } else if (tempWidth >= 2700) {
                        quantity += 6;
                    } else {
                        quantity += 5;
                    }

                });

                quantity = this.toMeter(height * quantity);

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'rabat_zwart') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    const tempWidth = this.configuratorService?.getWallWidth(wall) ?? 0;
                    let tempHeight = height;

                    if (this.parapet?.enabled) {
                        tempHeight -= borstweringHeight;
                    }

                    if (wall.woodWall.outerColor === 'zwart') {
                        quantity += this.toSqMeter(tempHeight * tempWidth);
                    }

                    if (wall.woodWall.innerEnable && wall.woodWall.innerColor === 'zwart') {
                        quantity += this.toSqMeter(tempHeight * tempWidth);
                    }

                    if (wall.inner) {
                        if (wall.woodWall.outerColor === 'zwart') {
                            quantity += this.calculateTriangleArea();
                        }

                        if (wall.woodWall.innerEnable && wall.woodWall.innerColor === 'zwart') {
                            quantity += this.calculateTriangleArea();
                        }
                    }
                });

                if ((this.roof?.walls.left.name === 'wall' || this.roof?.walls.left.name === '1window') && this.roof?.walls.left.outerWallType === 'zwart') {
                    quantity += this.calculateTriangleArea();
                }

                if ((this.roof?.walls.right.name === 'wall' || this.roof?.walls.right.name === '1window') && this.roof?.walls.right.outerWallType === 'zwart') {
                    quantity += this.calculateTriangleArea();
                }

                if ((this.roof?.walls.left.name === 'wall' || this.roof?.walls.left.name === '1window') && this.roof?.walls.left.innerWallEnabled && this.roof?.walls.left.innerWallType === 'zwart') {
                    quantity += this.calculateTriangleArea() * 2;
                }

                if ((this.roof?.walls.right.name === 'wall' || this.roof?.walls.right.name === '1window') && this.roof?.walls.right.innerWallEnabled && this.roof?.walls.right.innerWallType === 'zwart') {
                    quantity += this.calculateTriangleArea() * 2;
                }

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'rabat_naturel') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    const tempWidth = this.configuratorService?.getWallWidth(wall) ?? 0;
                    let tempHeight = height;

                    if (this.parapet?.enabled) {
                        tempHeight -= borstweringHeight;
                    }

                    if (wall.woodWall.outerColor === 'naturel') {
                        quantity += this.toSqMeter(tempHeight * tempWidth);
                    }

                    if (wall.woodWall.innerEnable && wall.woodWall.innerColor === 'naturel') {
                        quantity += this.toSqMeter(tempHeight * tempWidth);
                    }

                    if (wall.inner) {
                        if (wall.woodWall.outerColor === 'naturel') {
                            quantity += this.calculateTriangleArea();
                        }

                        if (wall.woodWall.innerEnable && wall.woodWall.innerColor === 'naturel') {
                            quantity += this.calculateTriangleArea();
                        }
                    }
                });

                if ((this.roof?.walls.left.name === 'wall' || this.roof?.walls.left.name === '1window') && this.roof?.walls.left.outerWallType === 'naturel') {
                    quantity += this.calculateTriangleArea();
                }

                if ((this.roof?.walls.right.name === 'wall' || this.roof?.walls.right.name === '1window') && this.roof?.walls.right.outerWallType === 'naturel') {
                    quantity += this.calculateTriangleArea();
                }

                if ((this.roof?.walls.left.name === 'wall' || this.roof?.walls.left.name === '1window') && this.roof?.walls.left.innerWallEnabled && this.roof?.walls.left.innerWallType === 'naturel') {
                    quantity += this.calculateTriangleArea() * 2;
                }

                if ((this.roof?.walls.right.name === 'wall' || this.roof?.walls.right.name === '1window') && this.roof?.walls.right.innerWallEnabled && this.roof?.walls.right.innerWallType === 'naturel') {
                    quantity += this.calculateTriangleArea() * 2;
                }

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'borstwering_metselwerk') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (this.parapet?.enabled && this.parapet.type === 'metselwerk') {
                        quantity += this.configuratorService?.getWallWidth(wall) ?? 0;
                    }
                });

                quantity = this.toMeter(quantity);

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'borstwering_beton') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (this.parapet?.enabled && this.parapet.type === 'vezelplaat') {
                        quantity += this.configuratorService?.getWallWidth(wall) ?? 0;
                    }
                });

                quantity = this.toMeter(quantity);

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'verhoogde') {
                const quantity = this.calculateBetonproer();

                return {
                    ...item,
                    quantity: quantity,
                    total: quantity * item.price
                };
            }

            if (item.name === 'raam_schuurdeel') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (wall.woodWall.outerColor === 'wall_potdeksel_window_800x1200' || wall.woodWall.outerColor === 'wall_potdeksel_window_door_left' || wall.woodWall.outerColor === 'wall_potdeksel_window_door_right') {
                        quantity++;
                    }

                    if (wall.woodWall.outerColor === 'wall_potdeksel_window2_800x1200') {
                        quantity += 2;
                    }
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'raam_nokgevel') {
                let quantity = 0;

                if (this.roof?.walls.left.name === '1window') {
                    quantity++;
                }

                if (this.roof?.walls.right.name === '1window') {
                    quantity++;
                }

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'kopgevel') {
                let quantity = 0;

                if (this.roof?.walls.left.name === '2windows') {
                    quantity += this.calculateTriangleArea();
                }

                if (this.roof?.walls.right.name === '2windows') {
                    quantity += this.calculateTriangleArea();
                }

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'deur_linksdraaiend') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (wall.woodWall.outerColor === 'wall_potdeksel_door_left' || wall.woodWall.outerColor === 'wall_potdeksel_window_door_left') {
                        quantity++;
                    }
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'deur_rechtsdraaiend') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (wall.woodWall.outerColor === 'wall_potdeksel_window_door_right' || wall.woodWall.outerColor === 'wall_potdeksel_window_door_right') {
                        quantity++;
                    }
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'dubbele_deur') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (wall.woodWall.outerColor === 'wall_potdeksel_double_door') {
                        quantity++;
                    }
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }


            if (item.name === 'garagedeur') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (wall.woodWall.outerColor === 'wall_potdeksel_garage_door') {
                        quantity++;
                    }
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'dakpannen') {
                let quantity = 0;

                if (this.roof?.type === 'tiles' || this.roof?.type === 'red tiles') {
                    quantity = this.calculateRoofSquare();
                }

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'geisoleerde_dakplaat') {
                let quantity = 0;

                if (this.roof?.type === 'isolated plates') {
                    quantity = this.calculateRoofSquare();
                }
                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'shutter') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    wall.elementWall.elements.forEach(element => {
                        if (element === 'zhaluzi') {
                            quantity++;
                        }
                    });
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'vaste_raam_1') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    wall.elementWall.elements.forEach(element => {
                        if (element === 'glass_1m') {
                            quantity++;
                        }
                    });
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'vaste_raam_2') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    wall.elementWall.elements.forEach(element => {
                        if (element === 'glass_2m') {
                            quantity++;
                        }
                    });
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'frame_boven_2') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (wall.slidingGlassWall === 'glass_door_2m') {
                        quantity++;
                    }
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }


            if (item.name === 'frame_boven_3') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (wall.slidingGlassWall === 'glass_door_3m') {
                        quantity++;
                    }
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'frame_boven_4') {
                let quantity = 0;

                this.walls?.forEach((wall) => {
                    if (wall.slidingGlassWall === 'glass_door_4m') {
                        quantity++;
                    }
                });

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'dakgoot_zinkkleur') {
                let quantity = 0;

                if (this.gutter?.enabled) {
                    quantity += this.toMeter(width * 2);
                }

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'regenwaterafvoer_zinkkleur') {
                let quantity = 0;

                if (this.gutter?.enabled) {
                    quantity += this.toMeter(height * 2);
                }

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'dakgoot_zink_zwart') {
                let quantity = 0;

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            if (item.name === 'regenwaterafvoer_zink_zwart') {
                let quantity = 0;

                return {
                    ...item, quantity: quantity, total: quantity * item.price
                };
            }

            return item;
        });

        return this.data;
    };
}

export default CalculationService;