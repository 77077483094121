import React from 'react';
import './TotalPrice.css';
import {useConfiguration} from "src/contexts/ConfigurationContext";
import prices from "src/prices";

const TotalPrice: React.FC = () => {
    const {calculation} = useConfiguration();

    return (
        <div className="configurator-total-price">
            <span>{calculation.calculateConfiguration(prices) && calculation.getFormatTotalPrice()}</span>
            <span>incl BTW</span>
        </div>
    );
};

export default TotalPrice;