import {useReducer} from 'react';
import {Configuration, Dimensions, Parapet, Roof, Gutter, Wall} from "src/types/configuration";

type State = Configuration;
type Action =
    | { type: 'SET_CONFIGURATION', payload: Configuration }
    | { type: 'SET_ROOF_TYPE', payload: string }
    | { type: 'SET_DIMENSIONS', payload: Dimensions }
    | { type: 'SET_PARAPET', payload: Parapet }
    | { type: 'SET_ROOF', payload: Roof }
    | { type: 'SET_GUTTER', payload: Gutter }
    | { type: 'SET_WALLS', payload: Wall[] }
    | { type: 'SET_STORAGES', payload: {left: boolean; right: boolean} };

const initialConfiguration: Configuration = {
    roofType: 'platdak',
    dimensions: {
        width: 6000,
        depth: 4000,
        height: 2200,
        angle: 30,
        overhangFront: 0,
        overhangBack: 0,
        overhangLeft: 0,
        overhangRight: 0,
        leftStorageWidth: 3000,
        rightStorageWidth: 3000
    },
    parapet: {enabled: false, height: 50, type: 'metselwerk'},
    roof: {
        type: "dakpanprofielplaten zwart", walls: {
            left: {
                name: 'empty',
                rotate: false,
                innerWallEnabled: false,
                innerWallType: 'naturel',
                outerWallType: 'naturel',
                window: 'wit',
            },
            right: {
                name: 'empty',
                rotate: false,
                innerWallEnabled: false,
                innerWallType: 'naturel',
                outerWallType: 'naturel',
                window: 'wit',
            }
        }
    },
    gutter: {enabled: false, type: "zink"},
    walls: [],
    storages: {left: false, right: false},
};

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_CONFIGURATION':
            return {...action.payload};
        case 'SET_ROOF_TYPE':
            return {...state, roofType: action.payload};
        case 'SET_DIMENSIONS':
            return {...state, dimensions: action.payload};
        case 'SET_PARAPET':
            return {...state, parapet: action.payload};
        case 'SET_ROOF':
            return {...state, roof: action.payload};
        case 'SET_GUTTER':
            return {...state, gutter: action.payload};
        case 'SET_WALLS':
            return {...state, walls: action.payload};
        case 'SET_STORAGES':
            return {...state, storages: action.payload};
        default:
            return state;
    }
}

export function useConfigurationState() {
    const [state, dispatch] = useReducer(reducer, initialConfiguration);

    return {state, dispatch};
}