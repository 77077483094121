import React, {useState, useEffect} from 'react';
import RadioBlocks from "src/components/Input/RadioBlocks/RadioBlocks";
import Number from "src/components/Input/Number/Number";
import Option from "src/components/Input/Option/Option";
import ColorSelect from "src/components/Input/Select/ColorSelect";
import {useConfiguration} from "src/contexts/ConfigurationContext";

interface ColorTabProps {
    storage: string;
}

const ColorTab: React.FC<ColorTabProps> = ({storage}) => {
    const {state, setDimensions} = useConfiguration();
    const [width, setWidth] = useState(storage === 'left' ? state.dimensions.leftStorageWidth : state.dimensions.rightStorageWidth);
    const [outerColor, setOuterColor] = useState('');
    const [innerColor, setInnerColor] = useState('');

    useEffect(() => {
        const prevWidth = storage === 'left' ? state.dimensions.leftStorageWidth : state.dimensions.rightStorageWidth;

        if (prevWidth === width) return;

        if (storage === 'left') {
            let configurationWidth = state.dimensions.width;

            if (state.dimensions.width < (width + state.dimensions.rightStorageWidth)) {
                configurationWidth += width - prevWidth;
            }

            setDimensions({
                ...state.dimensions,
                width: configurationWidth,
                leftStorageWidth: width
            });
        } else if (storage === 'right') {
            let configurationWidth = state.dimensions.width;

            if (state.dimensions.width < (width + state.dimensions.leftStorageWidth)) {
                configurationWidth += width - prevWidth;
            }

            setDimensions({
                ...state.dimensions,
                width: configurationWidth,
                rightStorageWidth: width
            });
        }
    }, [width, setDimensions, state, storage]);
    return (
        <>
            <Number label="Breedte" value={width} onChange={setWidth} min={3000} max={12000}
                    step={500}
                    subStep={100}/>
            <Option image={`${process.env.PUBLIC_URL}/images/buitenwand.png`} name="Buitenwand"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} components={[<ColorSelect value={outerColor} onChange={setOuterColor}/>]}
                    value={false} onChange={() => {
            }}/>
            <Option image={`${process.env.PUBLIC_URL}/images/buitenwand.png`} name="Binnenwand"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} components={[<ColorSelect value={innerColor} onChange={setInnerColor}/>]}
                    value={false} onChange={() => {
            }}/>
        </>
    );
}

export default ColorTab;