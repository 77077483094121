import {GutterOversize} from "src/types/configurator";
import {Wall, RoofWall, Dimensions, Position, Coordinate, Parapet} from "src/types/configuration";
import {UiWall} from "src/components/Tabs/WallsTab/components/WallSelector";
import {getWallPosition, isVertical} from "src/helpers/walls";

const EMPTY_SECTION_TYPE = 'empty';
const BEAM_SECTION_TYPE = 'beam';
const CONCRETE_BEAM_SECTION_TYPE = 'concrete_beam';
const CONCRETE_WOOD_COLUMN_TYPE = 'concrete_wood';
const WOOD_COLUMN_TYPE = 'wood';

class ConfiguratorService {
    private static instance: ConfiguratorService;
    private CONF: any;
    private APP: any;

    constructor() {
        if (ConfiguratorService.instance) {
            return ConfiguratorService.instance;
        }

        ConfiguratorService.instance = this;

        if (!window.CONF) {
            throw new Error("window.CONF is not available");
        }

        if (!window.APP) {
            throw new Error("window.APP is not available");
        }

        this.CONF = window.CONF;
        this.APP = window.APP;
    }

    // zadeldak - 3 angle roof, platdak - flat roof
    changeRoofType(roofType: string): void {
        this.CONF.changeRoofType(roofType);
        this.CONF.onUpdate();
    }

    getColumnsBalks(): number {
        return this.CONF.house.columns.length;
    }

    /**
     * Calculates and returns the number of columns based on the configuration.
     *
     * @return {number}
     */
    getColumns(): number {
        return (this.getColumnsBalks() / 2) - 1;
    }

    getRows(): number {
        return 1;
    }

    changeSize(size: Dimensions): void {
        const offsetBack = 350;
        const offsetFront = 350;
        const offsetLeft = 500;
        const offsetRight = 500;

        this.CONF.changeSize({
            width: size.width,
            depth: size.depth,
            height: size.height,
            back: size.overhangBack + offsetBack,
            front: size.overhangFront + offsetFront,
            left: size.overhangLeft + offsetLeft,
            right: size.overhangRight + offsetRight
        });
        this.CONF.onUpdate();
    }

    changeStorageWidth(leftWidth: number, rightWidth: number): void {
        this.CONF.setFixedLength({left: leftWidth, right: rightWidth, front: 0, back: 0});
        this.CONF.onUpdate();
    }

    toggleGutter(enable: boolean): void {
        this.CONF.enableGutter(0, enable);
        this.CONF.enableGutter(1, enable);
        this.CONF.onUpdate();
    }

    updateGutterMaterial(type: string): void {
        this.CONF.updateGutterMaterial(0, type);
        this.CONF.updateGutterMaterial(1, type);
        this.CONF.onUpdate();
    }

    updateGutterOversize(oversize: GutterOversize): void {
        this.CONF.updateGutterOversize(0, oversize);
        this.CONF.updateGutterOversize(1, oversize);
    }

    changeRoofMaterial(material: string): void {
        this.CONF.changeRoofMaterial(material.toLowerCase());
        this.CONF.onUpdate();
    }

    setColumnType(columnId: string, type: string): void {
        this.CONF.setColumnType(columnId, type);
        this.CONF.onUpdate();
    }

    changePanel(index: number, name: string, rotate: number, customization: string[]): void {
        this.CONF.changePanel(index, name, rotate, customization);
        this.CONF.onUpdate();
    }

    findWallByPosition(position: Position) {
        return this.CONF.house.walls.find((section: any) =>
            this.isPositionMatch(section, position) ||
            this.isPositionMatch(section, {start: position.end, end: position.start})
        );
    }

    findWallByUiWall = (selectedWall: UiWall | null) => {
        if (!selectedWall) return null;

        const position = getWallPosition(selectedWall.id);

        return this.findWallByPosition(position);
    };

    addWall(position: Position) {
        this.CONF.addWall(position.start, position.end);

        return this.findWallByPosition(position);
    };

    removeWall(wallId: number): void {
        this.CONF.removeWall(wallId);
        this.CONF.onUpdate();
    }

    isPositionMatch = (section: any, position: Position) => {
        return section.start.x === position.start.x && section.start.y === position.start.y &&
            section.end.x === position.end.x && section.end.y === position.end.y;
    }

    addElement(wallId: number, wallName: string, customizations: string[] = [], separator: string = BEAM_SECTION_TYPE) {
        this.CONF.addSectionToWall(wallId, wallName, customizations, separator);
    };

    wallExist(wallId: number) {
        return this.CONF.house.walls.some((wall: { id: number }) => wall.id === wallId);
    };

    getWallWidth(wall: Wall) {
        const configWall = this.findWallByPosition(wall.position);

        if (!configWall?.sections) return 0;

        let width = 0;

        return configWall.sections.reduce((width: number, element: { type: string; length: number; }) => {
            if (element.type.includes('potdeksel')) {
                width += element.length;
            }
            return width;
        }, 0);
    }

    canAddSectionToWall(wallId: number, sectionType: string, useFullWall: boolean = false, quantityLastElements: number = 0): boolean {
        const sectionData = this.CONF.getSectionData(sectionType);

        if (!sectionData) {
            console.error(`Section data with type name ${sectionType} not found`);
            return false;
        }

        /** HARDCODE to remove */
        const separatorData = this.CONF.getSectionData(
            this.CONF.parameters.wallSeparatorType
        );

        if (!separatorData) {
            console.error(
                `Separator type '${this.CONF.parameters.wallSeparatorType}' section data not found. Check sections data`
            );

            return false;
        }

        const wall = this.CONF.getWallById(wallId);

        if (!wall) {
            console.error(`Wall with id '${wallId}' not found`);
            return false;
        }

        const startIndex = 0;
        let endIndex = 0;
        let i = 0;
        let selectedSections = [];

        if (quantityLastElements > 0) {
            wall.sections.forEach((section: { type: any; }, index: number) => {
                if (section.type === EMPTY_SECTION_TYPE || section.type === BEAM_SECTION_TYPE || section.type === CONCRETE_BEAM_SECTION_TYPE) {
                    return;
                }

                if (i <= quantityLastElements) {
                    endIndex = index;
                    i++;
                }
            });

            if (wall.sections[endIndex - 1]?.type === EMPTY_SECTION_TYPE || wall.sections[endIndex - 1]?.type === BEAM_SECTION_TYPE || wall.sections[endIndex - 1]?.type === CONCRETE_BEAM_SECTION_TYPE) {
                endIndex--;
            }

            if (wall.sections[endIndex - 1]?.type === EMPTY_SECTION_TYPE || wall.sections[endIndex - 1]?.type === BEAM_SECTION_TYPE || wall.sections[endIndex - 1]?.type === CONCRETE_BEAM_SECTION_TYPE) {
                endIndex--;
            }

            selectedSections = wall.sections.slice(startIndex, endIndex);
        } else {
            endIndex = wall.sections.length - 1;

            if (wall.sections[endIndex]?.type === EMPTY_SECTION_TYPE || wall.sections[endIndex]?.type === BEAM_SECTION_TYPE || wall.sections[endIndex]?.type === CONCRETE_BEAM_SECTION_TYPE) {
                endIndex--;
            }

            if (wall.sections[endIndex]?.type === EMPTY_SECTION_TYPE || wall.sections[endIndex]?.type === BEAM_SECTION_TYPE || wall.sections[endIndex]?.type === CONCRETE_BEAM_SECTION_TYPE) {
                endIndex--;
            }

            selectedSections = wall.sections.slice(startIndex, endIndex + 1);
        }

        let lastWallSection = wall.sections[endIndex];
        const lastWallSectionData = lastWallSection ? this.CONF.getSectionData(lastWallSection.type) : undefined;
        const needSeparator = lastWallSectionData ? sectionData.group !== "" && sectionData.group !== lastWallSectionData.group : false;

        const minUsedSpace = selectedSections.reduce(
            (value: number, section: { type: any; }) => {
                const sd = this.CONF.getSectionData(section.type);

                if (!sd) {
                    return 0;
                }

                return value + sd.size.min;
            },
            needSeparator ? separatorData.size.min : 0
        );

        //const freeSpace = wall.length - usedSpace;
        let freeSpace = this.getWallLength(wall) - minUsedSpace;

        // if check for as empty wall that freeSpace === wall.length
        if (useFullWall) {
            freeSpace += minUsedSpace;
        }

        return freeSpace >= sectionData.size.min;
    }

    configuratorWallExist(wallId: number) {
        return window.CONF.house.walls.find((wall: { id: number; }) => wall.id === wallId) !== undefined;
    }

    canAddElement(wallId: number, element: string | null = 'zhaluzi', useFullWall = false, quantityLastElements = 0) {
        if (!element) element = 'zhaluzi';

        if (!this.configuratorWallExist(wallId)) return false;

        return this.canAddSectionToWall(wallId, element, useFullWall, quantityLastElements);
    };

    getWallLength(wall: { start: Coordinate; end: Coordinate; }): number {
        const house = this.CONF.house;

        if (isVertical(wall.start, wall.end)) {
            const i = Math.min(wall.start.y, wall.end.y);
            return house.sideLength[i];
        } else {
            const i = Math.min(wall.start.x, wall.end.x);
            return house.frontLength[i];
        }
    }

    enableParapet(parapetEnabled: boolean) {
        for (let column of this.CONF.house.columns) {
            if (parapetEnabled && column.type === WOOD_COLUMN_TYPE) {
                this.CONF.setColumnType(column.id, "concrete_wood");
                continue;
            }

            if (!parapetEnabled && column.type === CONCRETE_WOOD_COLUMN_TYPE) {
                this.CONF.setColumnType(column.id, WOOD_COLUMN_TYPE);
            }
        }

        this.CONF?.onUpdate();
    }

    setParapet(parapetType: string) {
        for (let wall of this.CONF.house.walls) {
            const elements = wall.sections;
            window.CONF.removeSectionsFromWall(wall.id);

            for (let element of elements) {
                const customization = element.customization;
                let type = element.type;

                if (type.startsWith('brick')) {
                    type = type.replace('wall', 'brick');
                }

                if (parapetType === 'vezelplaat' && !customization.includes('vezelplaat')) {
                    customization.push('vezelplaat');
                }

                if (parapetType === 'metselwerk' && customization.includes('vezelplaat')) {
                    customization.splice(customization.indexOf('vezelplaat'), 1);
                }

                this.addElement(wall.id, type, customization, CONCRETE_WOOD_COLUMN_TYPE);
            }
        }
    }

    setRoofing(roofing: string) {
        this.CONF.changeRoofMaterial(roofing);
        this.CONF.onUpdate();
    }

    setRoofWall(roofWall: RoofWall, side: string) {
        const sideId = side === 'left' ? 0 : 1;
        const customization = [];
        let name = roofWall.name;

        if (roofWall.name === 'wall' || roofWall.name === '1window') {
            if (roofWall.innerWallEnabled) name = 'wall_2';
            if (roofWall.outerWallType === 'zwart') customization.push('dark');
            if (roofWall.innerWallEnabled && roofWall.innerWallType === 'zwart') customization.push('dark_inner');
        }

        if (roofWall.name === '1window') {
            if (roofWall.innerWallEnabled) name = '1window_2';
            if (roofWall.window === 'wit') customization.push('white_window');
            if (roofWall.window === 'zwart') customization.push('dark_window');
            if (roofWall.window === 'houtkleur') customization.push('window_wood');
        }

        this.CONF.changePanel(sideId, name, 0, customization);
        this.CONF.onUpdate();
    }

    async getScreenShots() {
        const rotationAngles = [1.6, 3.1, 4.6, -3.3];
        const screenshots: string[] = [];

        for (const rotationY of rotationAngles) {
            this.APP.scene.rotation.y = rotationY;
            console.log(`Taking screenshot with scene.rotation.y = ${rotationY}`);

            if (this.APP && this.APP.renderer && this.APP.canvas) {
                const renderer = this.APP.renderer;
                const canvas = renderer.domElement;
                renderer.render(this.APP.scene, this.APP.scene.camera);

                const screenshot = await new Promise<string>((resolve) => {
                    requestAnimationFrame(() => {
                        const screenshot = canvas.toDataURL("image/png");
                        resolve(screenshot);
                    });
                });

                if (screenshot.length > 1000) {
                    screenshots.push(screenshot);
                    console.log(`Screenshot captured. Total: ${screenshots.length}`);
                }
            }
        }

        return screenshots;
    }

    getSlidingGlassDoor(wall: Wall) {
        const configWall = this.findWallByPosition(wall.position);

        if (this.canAddSectionToWall(configWall.id, 'glass_door_4m', true)) {
            return 'glass_door_4m';
        }

        if (this.canAddSectionToWall(configWall.id, 'glass_door_3m', true)) {
            return 'glass_door_3m';
        }

        if (this.canAddSectionToWall(configWall.id, 'glass_door_2m', true)) {
            return 'glass_door_2m';
        }

        return 'empty';
    }

    updateWalls(walls: Wall[], parapet: Parapet) {
        const columns = this.getColumns();

        console.log(`Updating walls`, walls);
        for (let i = 2; i <= columns; i++) {
            window.CONF.changePanel(i, 'empty', 0, []);
        }

        walls.forEach(wall => {
            this.updateWall(wall);

            return;
            //
            // let configWall = this.findWallByPosition(wall.position);
            //
            // // hide horizontal walls
            // if (wall.id.startsWith(`h-`) && wall.position.start.x >= columns && wall.position.end.x >= columns) {
            //     return;
            // }
            //
            // // hide vertical walls
            // if (wall.id.startsWith(`v-`) && (wall.position.start.x > columns)) {
            //     return;
            // }
            //
            // if (wall.inner) {
            //     configWall = window.CONF.house.walls.find(section =>
            //         this.isPositionMatch(section, {start: wall.position.start, end: wall.position.end})
            //     );
            //
            //     if (wall.rotate) {
            //         if (!configWall) {
            //             configWall = window.CONF.house.walls.find(section =>
            //                 this.isPositionMatch(section, {start: wall.position.end, end: wall.position.start})
            //             );
            //
            //             if (configWall) {
            //                 window.CONF.removeWall(configWall.id);
            //                 configWall = null;
            //             }
            //         }
            //     } else {
            //         if (configWall) {
            //             window.CONF.removeWall(configWall.id);
            //             configWall = null;
            //         }
            //     }
            // }
            //
            // if (!configWall) {
            //     configWall = wall.rotate
            //         ? this.addWall({start: wall.position.start, end: wall.position.end})
            //         : this.addWall({start: wall.position.end, end: wall.position.start});
            // }
            //
            // if (!configWall) return;
            //
            // // set roof wall
            // if (wall.inner && wall.typeWall !== 'empty') {
            //     const index = parseInt(wall.id.split('-')[2]) + 1;
            //     const type = wall.innerWallEnabled ? 'wall_2' : 'wall';
            //     const customization = [];
            //     let roofWallRotate = !wall.rotate ? 1 : 0;
            //     roofWallRotate = (columns > 3 && (wall.id === `v-0-3` || wall.id === `v-0-4`)) ? wall.rotate ? 1 : 0 : roofWallRotate;
            //
            //     if (wall?.outerWallType === 'zwart') customization.push('dark');
            //     if (wall?.innerWallType === 'zwart') customization.push('dark_inner');
            //
            //     window.CONF.changePanel(index, type, roofWallRotate, customization);
            // }
            //
            // // set align
            // window.CONF.setWallAlign(configWall.id, wall.elements[0]?.side === 'right' ? 1 : 0);
            //
            // wall.wallId = configWall.id;
            // let elementFillWood = false;
            //
            // // Add element
            // window.CONF.removeSectionsFromWall(configWall.id);
            //
            // if (wall.typeWall === 'empty') {
            //     this.addElement(configWall.id, EMPTY_SECTION_TYPE);
            // }
            //
            // wall.elements.forEach((element) => {
            //     if (element.name !== 'empty') {
            //         const customizations: string[] = [];
            //
            //         if (element.name === 'zhaluzi') {
            //             if (element.window === 'wit') customizations.push('white');
            //             if (element.window === 'zwart') customizations.push('dark');
            //         }
            //
            //         this.addElement(configWall.id, element.name, customizations, parapet.enabled ? CONCRETE_BEAM_SECTION_TYPE : BEAM_SECTION_TYPE);
            //     }
            //
            //     if (element.fillWoodEnabled) {
            //         elementFillWood = true;
            //     }
            // });
            //
            // // Set wall
            // if (wall.typeWall !== 'empty' || elementFillWood) {
            //     let fillType = wall.typeWall;
            //
            //     if (elementFillWood) fillType = 'wall_potdeksel';
            //     if (fillType !== 'empty' && wall.innerWallEnabled) fillType = fillType.replace('wall_potdeksel', 'wall_potdeksel2');
            //     if (fillType !== 'empty' && parapet.enabled) fillType = fillType.replace('wall', 'brick');
            //
            //     this.addElement(configWall.id, fillType);
            // }
            //
            // // set customization
            //
            // let i = 0;
            //
            // configWall.sections.forEach((element: { type: string; }, index: number) => {
            //     if (element.type === EMPTY_SECTION_TYPE || element.type === BEAM_SECTION_TYPE) return;
            //
            //     let customizations: string[] = [];
            //
            //     if (element.type.includes('potdeksel')) {
            //         if (wall.outerWallType === 'zwart') customizations.push('dark');
            //
            //         if (wall.innerWallEnabled && wall.innerWallType === 'zwart') customizations.push('dark_inner');
            //         if (wall.window === 'zwart' && !element.type.includes('garage_door')) customizations.push('dark_window', 'dark_door');
            //         if (wall.window === 'wit' && !element.type.includes('garage_door')) customizations.push('white_window', 'white_door');
            //     } else if (wall.elements[i] && !element.type.includes('garage_door')) {
            //         if (wall.elements[i].window === 'wit') {
            //             customizations.push('white');
            //         } else if (wall.elements[i].window === 'zwart') {
            //             customizations.push('dark');
            //         }
            //     }
            //
            //     if (parapet.enabled && parapet.type === 'vezelplaat') customizations.push('vezelplaat');
            //
            //     if (customizations.length > 0) {
            //         window.CONF.setCustomizationToWallSection(configWall.id, index, customizations);
            //     }
            //
            //     i++;
            // });


        });

        // Remove deleted walls
        this.CONF.house.walls.forEach((configWall: {
            id: number;
            start: { x: number; y: number };
            end: { x: number; y: number }
        }) => {
            const w = walls.find(wall => {
                const pos = wall.position;
                const start = configWall.start;
                const end = configWall.end
                return start.x === pos.start.x && start.y === pos.start.y && end.x === pos.end.x && end.y === pos.end.y || end.x === pos.start.x && end.y === pos.start.y && start.x === pos.end.x && start.y === pos.end.y;
            });

            if (!w) {
                window.CONF.removeSectionsFromWall(configWall.id);
                this.addElement(configWall.id, EMPTY_SECTION_TYPE);
                return;
            }
        });

        window.CONF.onUpdate();
    }

    private updateWall(wall: Wall) {
        let configWall = this.findWallByPosition(wall.position);

        // add inner wall
        if (!configWall && wall.inner) {
            configWall = this.addWall(wall.position);
        }

        if (!configWall) {
            return;
        }

        const useBlackOuter = wall.woodWall.outerColor.includes('black');
        let outerColor = wall.woodWall.outerColor.replace('_black', '');

        window.CONF.removeSectionsFromWall(configWall.id);
        window.CONF.setWallAlign(configWall.id, wall.woodWall.side === 'right' ? 1 : 0);

        // set elements
        this.setElements(wall, configWall);

        const hasNonEmptyElement = wall.elementWall.elements.length > 0 && wall.elementWall.elements.some((element) => element !== 'empty');

        if (hasNonEmptyElement) {
            return;
        }

        // set sliding glass wall
        this.setSlidingGlassWall(wall, configWall);

        if (wall.slidingGlassWall !== 'empty') return;

        // set walls
        if (wall.woodWall.door === 'empty' && wall.woodWall.window === 'empty' && outerColor !== 'empty') {
            this.addElement(configWall.id, outerColor, useBlackOuter ? ['dark'] : []);

            return;
        }

        if (wall.woodWall.door !== 'empty' && outerColor !== 'empty' && wall.woodWall.window === 'empty') {
            const doorType = `${outerColor}_${wall.woodWall.door}`;
            this.addElement(configWall.id, doorType, useBlackOuter ? ['dark'] : []);

            return;
        }

        if (wall.woodWall.door === 'empty' && outerColor !== 'empty' && wall.woodWall.window !== 'empty') {
            const doorType = `${outerColor}_${wall.woodWall.window}`;
            this.addElement(configWall.id, doorType, useBlackOuter ? ['dark'] : []);

            return;
        }

        if (wall.woodWall.door !== 'empty' && outerColor !== 'empty' && wall.woodWall.window !== 'empty') {
            outerColor = outerColor.replace('wall_potdeksel_2200x1000', 'potdeksel')
            const doorType = `${outerColor}_window_${wall.woodWall.window}_door_${wall.woodWall.door}`;
            this.addElement(configWall.id, doorType, useBlackOuter ? ['dark'] : []);

            return;
        }

        return;
    }

    private setElements(wall: Wall, configWall: { id: number; }) {
        // set element
        wall.elementWall.elements.forEach((element: string) => {
            this.addElement(configWall.id, element);
        });

        // set wood
        const useBlackOuter = wall.elementWall.outerColor.includes('black');
        let outerColor = wall.elementWall.outerColor.replace('_black', '');

        if (outerColor !== 'empty') {
            this.addElement(configWall.id, outerColor, useBlackOuter ? ['dark'] : []);
        }
    }

    private setSlidingGlassWall(wall: Wall, configWall: { id: number; }) {
        if (wall.slidingGlassWall === 'empty') {
            return;
        }

        this.addElement(configWall.id, wall.slidingGlassWall);
    }
}


export default ConfiguratorService;