import {useEffect} from 'react';
import {Configuration} from "../types/configuration";
import ConfiguratorService from "src/services/configuratorService";
import ConfigurationService from "src/services/configurationService";
import CalculationService from "src/services/calculationService";
import {addHouseWalls, removeHouseWalls} from "src/helpers/walls";
// @ts-ignore
import isEqual from "lodash/isEqual";

export function useSynchronizeServices(
    state: Configuration,
    configuration: ConfigurationService,
    calculation: CalculationService,
    configurator: ConfiguratorService,
    setWalls: (walls: Configuration['walls']) => void
) {
    useEffect(() => {
        // Update configurator

        // Roof type
        if (configuration.getRoofType() !== state.roofType) {
            configurator.changeRoofType(state.roofType);
        }

        // Dimensions & storages
        configurator.changeSize(state.dimensions);
        const leftStorageEnable = state.storages.left;
        const rightStorageEnable = state.storages.right;
        configurator.changeStorageWidth(
            leftStorageEnable ? state.dimensions.leftStorageWidth : 0,
            rightStorageEnable ? state.dimensions.rightStorageWidth : 0
        );

        // Parapet
        const currentParapet = configuration.getParapet();

        if (currentParapet.enabled !== state.parapet.enabled) {
            configurator.enableParapet(state.parapet.enabled);
        }

        if (state.parapet.enabled && currentParapet.type !== state.parapet.type) {
            configurator.setParapet(state.parapet.type);
        }

        // Roof
        const currentRoof = configuration.getRoof();

        if (!isEqual(currentRoof.type, state.roof.type)) {
            configurator.setRoofing(state.roof.type);
        }

        if (!isEqual(currentRoof.walls.left, state.roof.walls.left)) {
            configurator.setRoofWall(state.roof.walls.left, 'left');
        }

        if (!isEqual(currentRoof.walls.right, state.roof.walls.right)) {
            configurator.setRoofWall(state.roof.walls.right, 'right');
        }

        // Gutter
        const currentGutter = configuration.getGutter();
        console.log('Enabling gutter', currentGutter, state.gutter);

        if (currentGutter.enabled !== state.gutter.enabled) {
            configurator.toggleGutter(state.gutter.enabled);
        }

        if (configuration.getRoofType() !== 'platdak' && state.roofType === 'platdak') {
            state.gutter.enabled = false;
            configurator.toggleGutter(state.gutter.enabled);
        }

        if (state.gutter.enabled && currentGutter.type !== state.gutter.type) {
            configurator.updateGutterMaterial(state.gutter.type);
        }

        // Walls and storages
        const prevStorages = configuration.getStorages();

        if (prevStorages.left !== state.storages.left) {
            if (state.storages.left) {
                const newWalls = addHouseWalls('left', state, configuration, configurator);
                setWalls(newWalls);
            } else {
                const newWalls = removeHouseWalls('left', state, configuration, configurator);
                setWalls(newWalls);
            }
        }

        if (prevStorages.right !== state.storages.right) {
            if (state.storages.right) {
                const newWalls = addHouseWalls('right', state, configuration, configurator);
                setWalls(newWalls);
            } else {
                const newWalls = removeHouseWalls('right', state, configuration, configurator);
                setWalls(newWalls);
            }
        }

        // Update walls if they changed
        const currentWalls = configuration.getWalls();

        if (!isEqual(currentWalls, state.walls)) {
            configurator.updateWalls(state.walls, state.parapet);
        }

        // Update ConfigurationService
        configuration.setRoofType(state.roofType);
        configuration.setDimensions(state.dimensions);
        configuration.setParapet(state.parapet);
        configuration.setRoof(state.roof);
        configuration.setGutter(state.gutter);
        configuration.setWalls(state.walls);
        configuration.setStorages(state.storages);

        // Update CalculationService
        calculation.setDimensions(state.dimensions);
        calculation.setParapet(state.parapet);
        calculation.setRoof(state.roof);
        calculation.setGutter(state.gutter);
        calculation.setWalls(state.walls);
        calculation.setStorages(state.storages);
    }, [state, configuration, calculation, configurator, setWalls]);
}
