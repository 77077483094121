import React from 'react';
import {useView} from "src/contexts/ViewContext";
import './LeftBar.css';

const LeftBar: React.FC = () => {
    const {is3DView, setIs3DView, toggleOptionsVisibility} = useView();

    const handleEnable3D = () => {
        setIs3DView(true);
    }

    const handleEnable2D = () => {
        setIs3DView(false);
    }

    return (
        <div className="left-bar">
            <button className={`left-bar-button toggle-3d-button ${is3DView ? 'active' : ''}`} onClick={handleEnable3D}>
                <svg width="68" height="69" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M33.9999 22.5L23.6885 28.4225V40.5775L33.9999 46.5L44.3113 40.5775V28.4225L33.9999 22.5ZM42.1986 28.8304L39.8478 30.1806C38.5216 28.3899 36.3942 27.2268 33.9999 27.2268C31.6056 27.2268 29.4781 28.3899 28.152 30.1806L25.8012 28.8304L33.9999 24.1214L42.1986 28.8304ZM33.9999 40.3672C30.7647 40.3672 28.1327 37.7351 28.1327 34.5C28.1327 31.2649 30.7647 28.6328 33.9999 28.6328C37.2351 28.6328 39.8671 31.2648 39.8671 34.5C39.8671 37.7352 37.2351 40.3672 33.9999 40.3672ZM25.0944 30.0458L27.4279 31.3861C26.9786 32.3306 26.7267 33.3863 26.7267 34.5C26.7267 38.2732 29.6151 41.3843 33.2969 41.739V44.4748L25.0944 39.7637V30.0458ZM34.7029 44.4748V41.739C38.3847 41.3843 41.2731 38.2733 41.2731 34.5C41.2731 33.3864 41.0212 32.3306 40.5718 31.3861L42.9053 30.0459V39.7637L34.7029 44.4748Z"
                    />
                    <path
                        d="M32.0871 31.2088H30.3398V32.6148H32.0871C32.144 32.6148 32.1903 32.6611 32.1903 32.718V33.6411C32.1903 33.698 32.144 33.7443 32.0871 33.7443H30.3398V35.1502H32.0871C32.144 35.1502 32.1903 35.1966 32.1903 35.2535V36.1765C32.1903 36.2334 32.144 36.2797 32.0871 36.2797H30.3398V37.6857H32.0871C32.9193 37.6857 33.5963 37.0087 33.5963 36.1765V35.2535C33.5963 34.9571 33.5101 34.6807 33.362 34.4473C33.5101 34.2138 33.5963 33.9374 33.5963 33.6411V32.718C33.5963 31.8858 32.9193 31.2088 32.0871 31.2088Z"
                    />
                    <path
                        d="M38.4367 33.1601C38.4367 32.0842 37.5614 31.2089 36.4854 31.2089H34.3604V37.6858H36.4854C37.5614 37.6858 38.4367 36.8104 38.4367 35.7345V33.1601ZM37.0307 35.7345C37.0307 36.0352 36.7861 36.2798 36.4854 36.2798H35.7663V32.6148H36.4854C36.7861 32.6148 37.0307 32.8594 37.0307 33.1601V35.7345Z"
                    />
                </svg>
            </button>
            <button className={`left-bar-button toggle-2d-button ${!is3DView ? 'active' : ''}`}
                    onClick={handleEnable2D}>
                <svg width="84" height="85" viewBox="0 0 84 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M37.5071 31.4663H31.0215V53.457H53.0123V46.9285H37.5071V31.4663ZM36.2185 32.7547V34.0432H34.93V35.3318H36.2185V36.6203H33.6416V37.9089H36.2185V39.1973H34.93V40.4859H36.2185V41.7744H33.6416V43.063H36.2185V44.3515H34.93V45.6399H36.2185V47.3075L32.31 51.2484V32.7547H36.2185ZM38.7956 48.217V49.5056H40.0842V48.217H41.3726V50.837H42.6611V48.217H43.9497V49.5056H45.2383V48.217H46.5268V50.837H47.8152V48.217H49.1038V49.5056H50.3923V48.217H51.7239V52.1685H33.2122L37.1313 48.217H38.7956Z"
                    />
                    <path
                        d="M39.8954 41.831L38.5288 45.931L42.6288 44.5643L53.0027 34.1904L50.2693 31.457L39.8954 41.831ZM48.4472 35.1015L49.3583 36.0127L43.1919 42.1789L42.2809 41.2678L48.4472 35.1015ZM40.5661 43.8936L41.0217 42.527L41.3696 42.1789L42.2809 43.0901L41.9327 43.4381L40.5661 43.8936ZM50.2693 35.1015L49.3583 34.1904L50.2693 33.2793L51.1804 34.1904L50.2693 35.1015Z"
                    />
                </svg>
            </button>
            <span className="left-bar-button" id="rotate-btn-info">
                Draaien
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4.75" y="1.75" width="15.5" height="21.5" rx="7.75" fill="white" stroke="#1C1D1F"
                          strokeWidth="1.5"/>
                    <path d="M6 5L8.5 2.5L12 1.5L11.5 12.5H4.5V8.5L6 5Z" fill="#474e4b"/>
                    <rect x="4.75" y="1.75" width="15.5" height="21.5" rx="7.75" stroke="#1C1D1F" strokeWidth="1.5"/>
                    <rect x="11.15" y="6.65" width="2.7" height="5.7" rx="1.35" stroke="#1C1D1F" strokeWidth="1.3"/>
                    <line x1="12.4498" y1="2" x2="12.4498" y2="7" stroke="#1C1D1F" strokeWidth="1.3"/>
                </svg>
            </span>
            <span className="left-bar-button" id="zoom-btn-info">
                Zoom
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4.75" y="1.75" width="15.5" height="21.5" rx="7.75" fill="white" stroke="#1C1D1F"
                          strokeWidth="1.5"/>
                    <rect x="11.15" y="6.65" width="2.7" height="5.7" rx="1.35" fill="#474e4b" stroke="#1C1D1F"
                          strokeWidth="1.3"/>
                    <line x1="12.4498" y1="2" x2="12.4498" y2="7" stroke="#1C1D1F" strokeWidth="1.3"/>
                </svg>
            </span>
            <span className="left-bar-button" id="move-btn-info">
                Verplaatsen
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4.75" y="1.75" width="15.5" height="21.5" rx="7.75" fill="white" stroke="#1C1D1F"
                          strokeWidth="1.5"/>
                    <path d="M18.5 5L16 2.5L12.5 1.5L13 7L13.5 9.5V12.5H20V8.5L18.5 5Z" fill="#474e4b"/>
                    <rect x="4.75" y="1.75" width="15.5" height="21.5" rx="7.75" stroke="#1C1D1F" strokeWidth="1.5"/>
                    <rect x="11.15" y="6.65" width="2.7" height="5.7" rx="1.35" stroke="#1C1D1F" strokeWidth="1.3"/>
                    <line x1="12.4498" y1="2" x2="12.4498" y2="7" stroke="#1C1D1F" strokeWidth="1.3"/>
                </svg>
            </span>
            <button className="left-bar-button toggle-legend" onClick={toggleOptionsVisibility}>i</button>
        </div>
    );
};

export default LeftBar;