const prices = [
    {name: 'betonproer', label: 'Betonpoer 20 x 20 x 60 cm', unit: 'stuk', price: 70.0, quantity: 0, total: 0},
    {name: 'staanders', label: 'Staanders Douglas 220 x 20 x 20 cm', unit: 'stuk', price: 90.0, quantity: 0, total: 0},
    {name: 'ringbalk', label: 'Ringbalk, spantbenen Douglas 20 x 20cm', unit: 'm', price: 40.0, quantity: 0, total: 0},
    {
        name: 'gordingbalken',
        label: 'Douglas gordingbalken  (onder isolatieplaten)',
        unit: 'm',
        price: 25.0,
        quantity: 0,
        total: 0
    },
    {name: 'sporen', label: 'Douglas Sporen  (onder dakpannen)', unit: 'm', price: 20.0, quantity: 0, total: 0},
    {name: 'boeideel', label: 'Boeideel 2.2 x 20 cm', unit: 'm', price: 8.0, quantity: 0, total: 0},
    {name: 'wandregels', label: 'Wandregels 70x45/95x45', unit: 'm', price: 3.0, quantity: 0, total: 0},
    {name: 'rabat_zwart', label: 'Rabat (zwart) 200 x 1.2 x 18 cm', unit: 'm', price: 5.0, quantity: 0, total: 0},
    {name: 'rabat_naturel', label: 'Rabat (naturel) 200 x 1.2 x 18 cm', unit: 'm', price: 5.0, quantity: 0, total: 0},
    {
        name: 'borstwering_metselwerk',
        label: 'Borstwering metselwerk (zonder onder fundatie, alleen deel boven maaiveld)',
        unit: 'm',
        price: 250.0,
        quantity: 0,
        total: 0
    },
    {
        name: 'borstwering_beton',
        label: 'Borstwering beton (zonder onder fundatie, alleen deel boven maaiveld)',
        unit: 'm',
        price: 200.0,
        quantity: 0,
        total: 0
    },
    {name: 'verhoogde', label: 'Verhoogde beton poer', unit: 'stuk', price: 90.0, quantity: 0, total: 0},
    {
        name: 'raam_schuurdeel',
        label: 'Raam schuurdeel 66 x 86 cm, vastmodel Douglas',
        unit: 'stuk',
        price: 550.0,
        quantity: 0,
        total: 0
    },
    {name: 'raam_nokgevel', label: 'Raam nokgevel 60x60 cm, vastmodel Douglas', unit: 'stuk', price: 400.0, quantity: 0, total: 0},
    {
        name: 'kopgevel',
        label: 'Beglazing kopgevel 2x. Ligt er aan hoe groot de punt is afm.: ca1500x1200',
        unit: 'm²',
        price: 1000.0,
        quantity: 0,
        total: 0
    },
    {
        name: 'deur_linksdraaiend',
        label: 'Enkele deur linksdraaiend 88 x 198 cm Douglas',
        unit: 'stuk',
        price: 985.0,
        quantity: 0,
        total: 0
    },
    {
        name: 'deur_rechtsdraaiend',
        label: 'Enkele deur rechtsdraaiend 88 x 198 cm Douglas',
        unit: 'stuk',
        price: 985.0,
        quantity: 0,
        total: 0
    },
    {name: 'dubbele_deur', label: 'Dubbele deur 176 x 198 cm Douglas', unit: 'stuk', price: 1810.0, quantity: 0, total: 0},
    {name: 'garagedeur', label: 'Garagedeur', unit: 'stuk', price: 4400.0, quantity: 0, total: 0},
    {name: 'dakpannen', label: 'Dakpannen', unit: 'm²', price: 120.0, quantity: 0, total: 0},
    {name: 'geisoleerde_dakplaat', label: 'Geisoleerde dakplaat', unit: 'm²', price: 50.0, quantity: 0, total: 0},
    {name: 'shutter', label: 'Shutter 100 x 220 cm', unit: 'stuk', price: 800.0, quantity: 0, total: 0},
    {name: 'vaste_raam_1', label: 'Vaste raam 1m100 x 220 cm', unit: 'stuk', price: 1000.0, quantity: 0, total: 0},
    {name: 'vaste_raam_2', label: 'Vaste raam 2m200 x 220 cm', unit: 'stuk', price: 1500.0, quantity: 0, total: 0},
    {name: 'frame_boven_2', label: 'Schuifdeur frame boven 2m200 x 220 cm', unit: 'stuk', price: 1600.0, quantity: 0, total: 0},
    {name: 'frame_boven_3', label: 'Schuifdeur frame boven 3m300 x 220 cm', unit: 'stuk', price: 2300.0, quantity: 0, total: 0},
    {name: 'frame_boven_4', label: 'Schuifdeur frame boven 4m400 x 220 cm', unit: 'stuk', price: 2900.0, quantity: 0, total: 0},
    {name: 'dakgoot_zinkkleur', label: 'Dakgoot zinkkleur', unit: 'm', price: 40.0, quantity: 0, total: 0},
    {name: 'regenwaterafvoer_zinkkleur', label: 'Regenwaterafvoer zinkkleur', unit: 'm', price: 25.0, quantity: 0, total: 0},
    {name: 'dakgoot_zink_zwart', label: 'Dakgoot zink zwart', unit: 'm', price: 45.0, quantity: 0, total: 0},
    {name: 'regenwaterafvoer_zink_zwart', label: 'Regenwaterafvoer zink zwart', unit: 'm', price: 30.0, quantity: 0, total: 0},
];

export default prices;