import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ViewProvider} from "src/contexts/ViewContext";
import {ConfigurationProvider} from "src/contexts/ConfigurationContext";

document.addEventListener('DOMContentLoaded', () => {
    const rootElement = document.getElementById('configurator-place');

    if (rootElement) {
        const root = ReactDOM.createRoot(rootElement as HTMLElement);
        root.render(
            <React.StrictMode>
                <ViewProvider>
                    <ConfigurationProvider>
                        <App/>
                    </ConfigurationProvider>
                </ViewProvider>
            </React.StrictMode>
        );
    } else {
        console.error("Element with id 'configurator-place' not found.");
    }
});

