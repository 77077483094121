export const HORIZONTAL_POSITIONS = {
    'h-0-0': {start: {x: 0, y: 0}, end: {x: 1, y: 0}},
    'h-0-1': {start: {x: 1, y: 0}, end: {x: 2, y: 0}},
    'h-0-2': {start: {x: 2, y: 0}, end: {x: 3, y: 0}},
    'h-0-3': {start: {x: 3, y: 0}, end: {x: 4, y: 0}},
    'h-0-4': {start: {x: 4, y: 0}, end: {x: 5, y: 0}},
    'h-1-0': {start: {x: 1, y: 1}, end: {x: 0, y: 1}},
    'h-1-1': {start: {x: 1, y: 1}, end: {x: 2, y: 1}},
    'h-1-2': {start: {x: 3, y: 1}, end: {x: 2, y: 1}},
    'h-1-3': {start: {x: 4, y: 1}, end: {x: 3, y: 1}},
    'h-1-4': {start: {x: 5, y: 1}, end: {x: 4, y: 1}},
    'h-2-0': {start: {x: 1, y: 2}, end: {x: 0, y: 2}},
    'h-2-1': {start: {x: 2, y: 2}, end: {x: 1, y: 2}},
    'h-2-2': {start: {x: 3, y: 2}, end: {x: 2, y: 2}},
    'h-2-3': {start: {x: 4, y: 2}, end: {x: 3, y: 2}},
    'h-2-4': {start: {x: 5, y: 2}, end: {x: 4, y: 2}},
};

export const VERTICAL_POSITIONS = {
    'v-0-0': {start: {x: 0, y: 1}, end: {x: 0, y: 0}},
    'v-0-1': {start: {x: 1, y: 1}, end: {x: 1, y: 0}},
    'v-0-2': {start: {x: 2, y: 1}, end: {x: 2, y: 0}},
    'v-0-3': {start: {x: 3, y: 0}, end: {x: 3, y: 1}},
    'v-0-4': {start: {x: 4, y: 0}, end: {x: 4, y: 1}},
    'v-0-5': {start: {x: 5, y: 1}, end: {x: 5, y: 0}},
    'v-1-0': {start: {x: 0, y: 2}, end: {x: 0, y: 1}},
    'v-1-1': {start: {x: 1, y: 1}, end: {x: 1, y: 2}},
    'v-1-2': {start: {x: 2, y: 1}, end: {x: 2, y: 2}},
    'v-1-3': {start: {x: 3, y: 1}, end: {x: 3, y: 2}},
    'v-1-4': {start: {x: 4, y: 1}, end: {x: 4, y: 2}},
    'v-1-5': {start: {x: 5, y: 1}, end: {x: 5, y: 2}},
};