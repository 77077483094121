import React, {useEffect} from 'react';
import './Option.css';
import {toEUR} from "src/helpers/calculation";

interface OptionProps {
    name: string;
    image: string;
    description: string;
    price: number;
    components: React.ReactNode[],
    value: boolean;
    onChange: (selected: boolean) => void;
}

const Option: React.FC<OptionProps> = ({image, name, description, price, components, value, onChange}) => {
    const [state, setState] = React.useState({
        showDetails: false,
        showComponents: value,
    });

    useEffect(() => {
        if (value === state.showComponents) return;
        setState(prev => ({...prev, showComponents: value}));
    }, [value]);

    const toggleState = (key: 'showDetails' | 'showComponents') => {
        setState(prev => ({...prev, [key]: !prev[key]}));
        onChange(!state[key]);
    };

    return (
        <div className={`option-container ${state.showComponents ? 'active' : ''}`}>
            <div className="option" onClick={() => toggleState('showComponents')}>
                <div className="icon">
                    <img src={image} alt={name}/>
                </div>
                <div className="name">
                    {name}
                </div>
                <div className="price">{toEUR(price)}</div>
                <div className="value"></div>
                <div className={`details ${state.showDetails ? 'open' : ''}`} onClick={(event) => {
                    toggleState('showDetails');
                    event.stopPropagation();
                }}>
                    <span>Details</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 16L18 10L16.59 8.59L12 13.17L7.41 8.59L6 10L12 16Z" fill="#170E0E"/>
                    </svg>
                </div>
                {state.showDetails && <div className="description">
                    {description}
                </div>}
            </div>
            {state.showComponents && components.length > 0 && (
                <div className="components">
                    {components.map((component, index) => (
                        <div key={index} className="component">
                            {component}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
};

export default Option;
