import {useState, useEffect} from "react";

const getWindowWidth = () => window.innerWidth;

export const useWindowResize = (): number => {
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    useEffect(() => {
        const updateWindowWidth = () => {
            const newWidth = getWindowWidth();
            setWindowWidth(newWidth);
        };

        window.addEventListener("resize", updateWindowWidth);
        return () => window.removeEventListener("resize", updateWindowWidth);
    }, []);

    return windowWidth;
};
