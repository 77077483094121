import React, {useState, useEffect} from 'react';
import RadioBlocks from "src/components/Input/RadioBlocks/RadioBlocks";
import Number from "src/components/Input/Number/Number";
import Option from "src/components/Input/Option/Option";
import HorizontalTabs from "src/components/HorizontalTabs/HorizontalTabs";
import ColorTab from "src/components/Tabs/StorageTab/ColorTab";
import HorizontalSwitch from "src/components/Input/HorizontalSwitch/HorizontalSwitch";
import SimpleRadioButtons from "src/components/Input/SimpleRadioButtons/SimpleRadioButtons";
import ColorSelect from "src/components/Input/Select/ColorSelect";
import WindowSelect from "src/components/Input/Select/WindowSelect";
import DoorSelect from "src/components/Input/Select/DoorSelect";
import {Wall} from "src/types/configuration";

const sideSwitchData = [
    {id: 1, label: 'Deur links & raam rechts', value: 'left'},
    {id: 2, label: 'Raam links & deur rechts', value: 'right'},
];

interface DoorWindowTabProps {
    wall: Wall;
    onDoorChange: (value: string) => void;
    onDoorTurningChange: (value: string) => void;
    onDoorWindowSideChange: (value: string) => void;
    onWindowChange: (value: string) => void;
}

const DoorWindowTab: React.FC<DoorWindowTabProps> = ({
                                                         wall,
                                                         onDoorChange,
                                                         onDoorTurningChange,
                                                         onDoorWindowSideChange,
                                                         onWindowChange
                                                     }) => {
    const doorWindowTabs = [
        {
            id: 1,
            title: 'Deur',
            component: <DoorSelect id="wall" value={wall.woodWall.door} subValue={wall.woodWall.doorSide}
                                   onChange={onDoorChange}
                                   onSubChange={onDoorTurningChange}/>
        },
        {
            id: 2, title: 'Raam', component: <>
                <HorizontalSwitch value={wall.woodWall.side} values={sideSwitchData}
                                  onChange={onDoorWindowSideChange}/>
                <WindowSelect value={wall.woodWall.window} onChange={onWindowChange}/>
            </>
        },
    ];

    return (
        <>
            <HorizontalTabs tabs={doorWindowTabs} key={'wall-door-window-tab'}/>
        </>
    );
}

export default DoorWindowTab;