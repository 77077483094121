import React, {useState, useEffect} from 'react';
import RadioBlocks from "src/components/Input/RadioBlocks/RadioBlocks";
import Number from "src/components/Input/Number/Number";
import Option from "src/components/Input/Option/Option";
import HorizontalTabs from "src/components/HorizontalTabs/HorizontalTabs";
import ColorTab from "src/components/Tabs/StorageTab/ColorTab";
import HorizontalSwitch from "src/components/Input/HorizontalSwitch/HorizontalSwitch";
import SimpleRadioButtons from "src/components/Input/SimpleRadioButtons/SimpleRadioButtons";
import DoorSelect from "src/components/Input/Select/DoorSelect";
import WindowSelect from "src/components/Input/Select/WindowSelect";

const sideSwitchData = [
    {id: 1, label: 'Deur links & raam rechts', value: 'left'},
    {id: 2, label: 'Raam links & deur rechts', value: 'right'},
];

const DoorWindowTab: React.FC = () => {
    const [doorSideSwitch, setDoorSideSwitch] = useState('left');
    const [windowSideSwitch, setWindowSideSwitch] = useState('left');
    const [door, setDoor] = useState('douglas-geschaafd11');
    const [doorSide, setDoorSide] = useState('left');
    const [window, setWindow] = useState('douglas-geschaafd21');

    const frontTabs = [
        {
            id: 1,
            title: 'Deur',
            component: <>
                <HorizontalSwitch value={doorSideSwitch} values={sideSwitchData} onChange={setDoorSideSwitch}/>
                <DoorSelect id="front-side" value={door} subValue={doorSide} onChange={setDoor}
                            onSubChange={setDoorSide}/>
            </>
        },
        {
            id: 2,
            title: 'Raam',
            component: <>
                <HorizontalSwitch value={windowSideSwitch} values={sideSwitchData} onChange={setWindowSideSwitch}/>
                <WindowSelect value={window} onChange={setWindow}/>
            </>
        },
    ];

    const backTabs = [
        {
            id: 1,
            title: 'Deur',
            component: <>
                <HorizontalSwitch value={doorSideSwitch} values={sideSwitchData} onChange={setDoorSideSwitch}/>
                <DoorSelect id="back-side" value={door} subValue={doorSide} onChange={setDoor}
                            onSubChange={setDoorSide}/>
            </>
        },
        {
            id: 2,
            title: 'Raam',
            component: <>
                <HorizontalSwitch value={windowSideSwitch} values={sideSwitchData} onChange={setWindowSideSwitch}/>
                <WindowSelect value={window} onChange={setWindow}/>
            </>
        },
    ];

    const leftTabs = [
        {
            id: 1,
            title: 'Deur',
            component: <>
                <HorizontalSwitch value={doorSideSwitch} values={sideSwitchData} onChange={setDoorSideSwitch}/>
                <DoorSelect id="left-side" value={door} subValue={doorSide} onChange={setDoor}
                            onSubChange={setDoorSide}/>
            </>
        },
        {
            id: 2,
            title: 'Raam',
            component: <>
                <HorizontalSwitch value={windowSideSwitch} values={sideSwitchData} onChange={setWindowSideSwitch}/>
                <WindowSelect value={window} onChange={setWindow}/>
            </>
        },
    ];

    const rightTabs = [
        {
            id: 1,
            title: 'Deur',
            component: <>
                <HorizontalSwitch value={doorSideSwitch} values={sideSwitchData} onChange={setDoorSideSwitch}/>
                <DoorSelect id="right-side" value={door} subValue={doorSide} onChange={setDoor}
                            onSubChange={setDoorSide}/>
            </>
        },
        {
            id: 2,
            title: 'Raam',
            component: <>
                <HorizontalSwitch value={windowSideSwitch} values={sideSwitchData} onChange={setWindowSideSwitch}/>
                <WindowSelect value={window} onChange={setWindow}/>
            </>
        },
    ];

    const sideTabs = [
        {
            id: 1,
            title: 'Voor',
            component: <HorizontalTabs tabs={frontTabs} key={'front-tab'}/>
        },
        {
            id: 2,
            title: 'Achter',
            component: <HorizontalTabs tabs={backTabs} key={'back-tab'}/>
        },
        {
            id: 3,
            title: 'Links',
            component: <HorizontalTabs tabs={leftTabs} key={'left-tab'}/>
        },
        {
            id: 4,
            title: 'Rechts',
            component: <HorizontalTabs tabs={rightTabs} key={'right-tab'}/>
        },
    ];

    return (
        <>
            <HorizontalTabs tabs={sideTabs} key={'door-window-tab'}/>
        </>
    );
}

export default DoorWindowTab;