import React, {MouseEvent} from "react";
import './ResultPopup.css';

interface ResultPopupProps {
    header: string;
    message: string;
    setShowSuccessPopup: (showSuccessPopup: boolean) => void;
}

const ResultPopup: React.FC<ResultPopupProps> = ({header, message, setShowSuccessPopup}) => {
    const hideForm = (event: MouseEvent<HTMLDivElement>) => {
        if (event.target !== event.currentTarget) return;
        setShowSuccessPopup(false);
    }

    const handleCloseButton = () => {
        setShowSuccessPopup(false);
    };

    return (
        <div className="result-popup" onClick={hideForm}>
            <div>
                {header && <h2>{header}</h2>}
                {message && <p>{message}</p>}
                <button onClick={handleCloseButton}>Ok</button>
            </div>
        </div>
    );
};

export default ResultPopup;