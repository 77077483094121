import React from 'react';
import './FinishTab.css';
import RadioBlocks from "../../Input/RadioBlocks/RadioBlocks";

const afwerkingData = [
    {
        id: 1,
        name: 'Doe het zelf',
        imageUrl: `${process.env.PUBLIC_URL}/images/afwerking-1.png`,
        color: '',
        value: 'empty',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 2,
        name: 'Op maat',
        imageUrl: `${process.env.PUBLIC_URL}/images/afwerking-2.png`,
        color: '',
        value: 'headstock',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
];

const FinishTab: React.FC = () => {
    return (
        <div className="finish-tab">
            <RadioBlocks blocks={afwerkingData} onChange={() => console.log('afwerking')}
                         value={'empty'}/>
        </div>
    );
};

export default FinishTab;