import React, {useState} from 'react';
import './HorizontalTabs.css';

interface HorizontalTabInterface {
    id: number;
    title: string;
    component: React.ReactNode;
}

interface HorizontalTabsProps {
    tabs: HorizontalTabInterface[];
    className?: string;
}

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({tabs, className}) => {
    const [activeTab, setActiveTab] = useState<number | null>(1);

    const handleTabClick = (id: number) => {
        setActiveTab(activeTab === id ? null : id);
    };

    return (
        <div className={`horizontal-tabs-container ${className ? className : ''}`}>
            <div className="horizontal-tabs">
                {tabs.map(tab => (
                    <div key={tab.id} className={`horizontal-tab-header ${activeTab === tab.id ? 'open' : ''}`}
                         onClick={() => handleTabClick(tab.id)}>
                        <span>{tab.title}</span>
                    </div>
                ))}
            </div>
            {tabs.map(tab => (
                <div key={tab.id} className={`horizontal-tab-content ${activeTab === tab.id ? 'active' : ''}`}>
                    {tab.component}
                </div>
            ))}
        </div>
    );
};

export default HorizontalTabs;
