import React, {MouseEvent, FormEvent, ChangeEvent, useState} from 'react';
import './SaveOfferForm.css';
import Text from "src/components/Input/Text/Text";
import {useConfiguration} from "src/contexts/ConfigurationContext";

interface SaveOfferFormProps {
    showForm: boolean;
    setShowForm: (showForm: boolean) => void;
    setShowSuccessPopup: (showSuccessPopup: boolean) => void;
}

const nlPostcodeRegex = /^[1-9][0-9]{3}\s?[A-Za-z]{2}$/;
const phoneRegex = /^\+?\d{10}$/;

const SaveOfferForm: React.FC<SaveOfferFormProps> = ({showForm, setShowForm, setShowSuccessPopup}) => {
    const {request} = useConfiguration();

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        street: '',
        house_number: '',
        city: '',
        postcode: '',
    });

    const [errors, setErrors] = useState<Partial<typeof formData>>({});

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const validateForm = (): boolean => {
        const newErrors: Partial<typeof formData> = {};

        for (const key in formData) {
            if (!formData[key as keyof typeof formData]) {
                newErrors[key as keyof typeof formData] = 'This field is required';
            }
        }

        if (formData.phone && !phoneRegex.test(formData.phone)) {
            newErrors.phone = 'Phone number should have exactly 10 digits.';
        }

        if (formData.postcode && !nlPostcodeRegex.test(formData.postcode)) {
            newErrors.postcode = 'Invalid NL postal code (e.g. "1234 AB").';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formElement = event.currentTarget;
        const formData = new FormData(formElement);
        const formObject: Record<string, any> = {};

        formData.forEach((value, key) => {
            formObject[key] = String(value);
        });

        try {
            const response = await request.addOffer(formObject);

            console.log('Success:', response.data);
            setShowForm(false);
            setShowSuccessPopup(true);
        } catch (error) {
            console.error('Error:', error);
            setShowForm(false);
            setShowSuccessPopup(true);
        }
    }

    const handleOverlayClick = (event: MouseEvent<HTMLDivElement>) => {
        if (event.target !== event.currentTarget) return;
        setShowForm(false);
    }

    if (!showForm) {
        return null;
    }

    const renderField = (name: keyof typeof formData, label: string) => (
        <div className="save-offer-form-field">
            <Text
                name={name}
                label={label}
                content={formData[name]}
                onChange={handleChange}
            />
            {errors[name] && <div className="error-msg">{errors[name]}</div>}
        </div>
    );

    return (
        <div className="save-offer-form" onClick={handleOverlayClick}>
            <form id="saveOfferForm" method="post" onSubmit={handleSubmit}>
                {renderField('first_name', 'First name')}
                {renderField('last_name', 'Last name')}
                {renderField('email', 'Email')}
                {renderField('phone', 'Phone')}
                {renderField('street', 'Street')}
                {renderField('house_number', 'House number')}
                {renderField('city', 'City')}
                {renderField('postcode', 'Postal code')}

                <button type="submit">Send</button>
            </form>
        </div>
    );
};

export default SaveOfferForm;
