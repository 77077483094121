import {VERTICAL_POSITIONS, HORIZONTAL_POSITIONS} from "src/helpers/constants";
import {Wall, Configuration, Position, Coordinate} from "src/types/configuration";
import ConfigurationService from "src/services/configurationService";
import ConfiguratorService from "src/services/configuratorService";

export const getWallPosition = (wallId: string): Position => {
    if (!wallId) return defaultPosition();

    // @ts-ignore
    return HORIZONTAL_POSITIONS[wallId] || VERTICAL_POSITIONS[wallId] || defaultPosition();
};

const defaultPosition = (): Position => ({
    start: {x: 0, y: 0},
    end: {x: 0, y: 0}
});

/**
 * Checks if object by coordinates is vertical
 *
 * @param {Coordinate} start - start coordiante
 * @param {Coordinate} end - end coordiante
 *
 * @returns {boolean} - is vertical
 */
export const isVertical = (start: Coordinate, end: Coordinate): boolean => {
    return start.x === end.x;
}

/**
 * Adds walls to a house based on the specified side and configuration.
 *
 * @param {'left' | 'right'} side - The side of the house where the walls should be added ('left' or 'right').
 * @param {Configuration} state - The current state of the house, including existing walls and dimensions.
 * @param {ConfigurationService} configuration - The service used for fetching default wall configurations.
 * @return {Wall[]} - An updated array of walls after adding the specified house walls.
 */
export function addHouseWalls(
    side: 'left' | 'right',
    state: Configuration,
    configuration: ConfigurationService,
    configurator: ConfiguratorService
): Wall[] {
    const {walls, dimensions} = state;

    if (side === 'left') {
        const wallIds = ['h-0-0', 'h-1-0', 'v-0-0', 'v-0-1'];
        const houseWalls: Wall[] = wallIds.map((wallId) => {
            const wall = configuration.getDefaultWall(wallId);

            if (wall.id === 'h-0-0') {
                wall.woodWall.outerColor = 'wall_potdeksel_2200x1000_black';
                wall.woodWall.door = 'enkel_6-ruits';
                wall.woodWall.window = 'uitzetraam_6ruits';
            } else if (wall.id === 'h-1-0') {
                wall.woodWall.outerColor = 'wall_potdeksel_2200x1000_black';
            } else if (wall.id === 'v-0-1') {
                wall.woodWall.outerColor = 'wall_potdeksel_2200x1000_black';
                wall.inner = true;
            } else if (wall.id === 'v-0-0') {
                wall.woodWall.outerColor = 'wall_potdeksel_2200x1000_black';
                wall.woodWall.window = 'uitzetraam_6ruits';
            }

            return wall;
        });

        const updatedWalls = walls.filter(wall => !wallIds.includes(wall.id));
        return [...updatedWalls, ...houseWalls];
    }

    if (side === 'right') {
        const columns = configurator.getColumns();
        const wallIds = [`h-0-${columns - 1}`, `h-1-${columns - 1}`, `v-0-${columns - 1}`, `v-0-${columns}`];

        const houseWalls: Wall[] = wallIds.map((wallId) => {
            const wall = configuration.getDefaultWall(wallId);

            if (wall.id === `h-0-${columns - 1}`) {
                wall.woodWall.outerColor = 'wall_potdeksel_2200x1000_black';
                wall.woodWall.door = 'enkel_6-ruits';
                wall.woodWall.window = 'uitzetraam_6ruits';
            } else if (wall.id === `h-1-${columns - 1}`) {
                wall.woodWall.outerColor = 'wall_potdeksel_2200x1000_black';
            } else if (wall.id === `v-0-${columns - 1}`) {
                wall.woodWall.outerColor = 'wall_potdeksel_2200x1000_black';
                wall.inner = true;
            } else if (wall.id === `v-0-${columns}`) {
                wall.woodWall.outerColor = 'wall_potdeksel_2200x1000_black';
                wall.woodWall.window = 'uitzetraam_6ruits';
            }

            return wall;
        });

        const updatedWalls = walls.filter(wall => !wallIds.includes(wall.id));
        return [...updatedWalls, ...houseWalls];
    }

    return state.walls;
}

/**
 * Removes specific walls from the house based on the given side and configuration.
 *
 * @param {'left' | 'right'} side - Specifies which side of the house the walls should be removed from (either 'left' or 'right').
 * @param {Configuration} state - An object containing the current configuration state, including dimensions and walls.
 * @param {ConfigurationService} configuration - A service object providing wall information and storage configuration.
 * @return {Wall[]} - A filtered array of walls after removing the specified walls from the given side.
 */
export function removeHouseWalls(
    side: 'left' | 'right',
    state: Configuration,
    configuration: ConfigurationService,
    configurator: ConfiguratorService
): Wall[] {
    const columns = configurator.getColumns();

    if (side === 'left') {
        let wallIds = ['h-0-0', 'h-1-0', 'v-0-0', 'v-0-1'];

        if (configuration.getStorages().right && 'v-0-1' === `v-0-${columns - 1}`) {
            wallIds = ['h-0-0', 'h-1-0', 'v-0-0'];
        }

        return configuration.getWalls().filter(wall => !wallIds.includes(wall.id));
    }

    if (side === 'right') {
        let wallIds = [`h-0-${columns - 1}`, `h-1-${columns - 1}`, `v-0-${columns - 1}`, `v-0-${columns}`];

        if (configuration.getStorages().left && 'v-0-1' === `v-0-${columns - 1}`) {
            wallIds = [`h-0-${columns - 1}`, `h-1-${columns - 1}`, `v-0-${columns}`];
        }

        return configuration.getWalls().filter(wall => !wallIds.includes(wall.id));
    }

    return state.walls;
}
