import React from 'react';
import './OptionText.css';

interface OptionTextProps {
    content: string;
}

const OptionText: React.FC<OptionTextProps> = ({content}) => {
    return (
        <div className="option-text">{content}</div>
    );
};

export default OptionText;