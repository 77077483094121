import React, {useState} from 'react';
import Option from "src/components/Input/Option/Option";
import ColorSelect from "src/components/Input/Select/ColorSelect";
import {Wall} from "src/types/configuration";

interface ColorTabProps {
    parent: string;
    wall: Wall | null;
    onSetOuterColor: (value: string) => void;
    onSetInnerColor: (value: string) => void;
    onSetInnerColorEnable: (value: boolean) => void;
}

const ColorTab: React.FC<ColorTabProps> = ({parent, wall, onSetInnerColor, onSetOuterColor, onSetInnerColorEnable}) => {
    const [outerColorEnable, setOuterColorEnable] = useState(false);

    const innerColorEnable = parent === 'walls-tab' ? wall?.woodWall.innerEnable : wall?.elementWall.innerEnable;
    const innerColor = parent === 'walls-tab' ? wall?.woodWall.innerColor : wall?.elementWall.innerColor;
    const outerColor = parent === 'walls-tab' ? wall?.woodWall.outerColor : wall?.elementWall.outerColor;

    return (
        <>
            <Option image={`${process.env.PUBLIC_URL}/images/buitenwand.png`} name="Buitenwand"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} value={outerColorEnable} onChange={setOuterColorEnable}
                    components={[<ColorSelect value={outerColor ?? ''} onChange={onSetOuterColor}/>]}/>
            <Option image={`${process.env.PUBLIC_URL}/images/buitenwand.png`} name="Binnenwand"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} value={innerColorEnable ?? false} onChange={onSetInnerColorEnable}
                    components={[<ColorSelect value={innerColor ?? ''} onChange={onSetInnerColor}/>]}/>
        </>
    );
}

export default ColorTab;