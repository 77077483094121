import ConfigurationService from "src/services/configurationService";
import axios from "axios";
import {Offer} from "src/types/offer";
import ConfiguratorService from "src/services/configuratorService";

class RequestService {
    private configurationService?: ConfigurationService;
    private configuratorService?: ConfiguratorService;
    private uuid?: string;
    private offer?: Offer;

    constructor(configuration: ConfigurationService, configurator: ConfiguratorService) {
        this.configurationService = configuration;
        this.configuratorService = configurator;
        this.uuid = <string>document.querySelector('[name="offerUuid"]')?.getAttribute('value');
    }

    async addOffer(formObject: any): Promise<any> {
        const screenshots = await this.configuratorService?.getScreenShots();

        formObject['prodType'] = "product";
        formObject['modType'] = "model1234";
        formObject['offDate'] = new Date().toISOString().slice(0, 10);
        formObject['products'] = [
            {
                "product_number": "P006749",
                "quantity": 1,
                "price": 187
            },
            {
                "product_number": "P006749",
                "quantity": 1,
                "price": 287
            },
            {
                "product_number": "P006464",
                "quantity": 1,
                "price": 387
            },
            {
                "product_number": "K064966",
                "quantity": 1,
                "price": 487
            },
        ];
        formObject["screenshot"] = screenshots && screenshots.length > 0 ? screenshots[0] : '';
        formObject["screenshot2"] = screenshots && screenshots.length > 1 ? screenshots[1] : '';
        formObject["screenshot3"] = screenshots && screenshots.length > 2 ? screenshots[2] : '';
        formObject["screenshot4"] = screenshots && screenshots.length > 3 ? screenshots[3] : '';
        formObject["price"] = 33400;

        formObject['configurator'] = btoa(JSON.stringify(this.configurationService?.getConfiguration()));

        return await axios.put('/configurator/api/offer', formObject, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
    }

    async getOffer() {
        if (!this.uuid) {
            return;
        }

        try {
            const response = await axios.get(`/configurator/${this.uuid}/data`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            this.offer = response.data.data;
        } catch (error) {
            console.error(error);
        }

        return this.offer;
    }

    async saveOffer() {
        const screenshots = await this.configuratorService?.getScreenShots();

        const updatedOffer = {
            ...this.offer,
            configurator: btoa(JSON.stringify(this.configurationService?.getConfiguration())),
            products: [
                {
                    "product_number": "K065309",
                    "quantity": 1,
                    "price": 1187
                },
                {
                    "product_number": "P006749",
                    "quantity": 1,
                    "price": 287
                },
                {
                    "product_number": "P006464",
                    "quantity": 1,
                    "price": 387
                },
                {
                    "product_number": "K064966",
                    "quantity": 1,
                    "price": 487
                },
            ],
            screenshot1: screenshots && screenshots.length > 0 ? screenshots[0] : '',
            screenshot2: screenshots && screenshots.length > 1 ? screenshots[1] : '',
            screenshot3: screenshots && screenshots.length > 2 ? screenshots[2] : '',
            screenshot4: screenshots && screenshots.length > 3 ? screenshots[3] : '',
            price: 23400,
        }

        try {
            const response = await axios.post(`/configurator/${this.uuid}/save`, updatedOffer, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            this.offer = response.data.data;
        } catch (error) {
            console.error(error);
        }
    }

    addOrder() {
        // @ts-ignore
        if (window.dealer) {
            const offerUUID = document.querySelector('input[name="userRole"]')?.getAttribute('value');
            // @ts-ignore
            window.dealer.createOrder();
        }
    }
}

export default RequestService;