import React from 'react';
import './Loader.css';

const Loader: React.FC = () => {
    return (
        <div id="configurator-loader">
            <div className="configuration-loader-wrapper">
                <div className="configurator-start-loader"></div>
                <div className="message">Momentje, we zetten uw overkapping in elkaar</div>
            </div>
        </div>
    );
};

export default Loader;