import React from 'react';
import './OptionsTab.css';
import Option from "src/components/Input/Option/Option";
import RadioBlocks from "src/components/Input/RadioBlocks/RadioBlocks";
import SimpleNumber from "src/components/Input/SimpleNumber/SimpleNumber";
import {useConfiguration} from "src/contexts/ConfigurationContext";

const foundationBeamsData = [
    {
        id: 1,
        name: 'Funderingsbalk kunststof robuust',
        imageUrl: `${process.env.PUBLIC_URL}/images/foundation-beam-1.png`,
        color: '',
        value: 'metselwerk1',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 2,
        name: 'Luxe antraciet',
        imageUrl: `${process.env.PUBLIC_URL}/images/foundation-beam-2.png`,
        color: '',
        value: 'metselwerk2',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
];

const concretePiesData = [
    {
        id: 1,
        name: 'Antraciet',
        imageUrl: `${process.env.PUBLIC_URL}/images/concrete-pies-1.png`,
        color: '',
        value: 'metselwerk3',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.',
        component: <SimpleNumber value={0} onChange={() => console.log('house breedte')} min={0} max={1000} step={1}/>
    },
    {
        id: 2,
        name: 'Luxe antraciet',
        imageUrl: `${process.env.PUBLIC_URL}/images/concrete-pies-2.png`,
        color: '',
        value: 'metselwerk4',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.',
        component: <SimpleNumber value={0} onChange={() => console.log('house breedte')} min={0} max={1000} step={1}/>
    },
    {
        id: 3,
        name: 'Half model antraciet',
        imageUrl: `${process.env.PUBLIC_URL}/images/concrete-pies-3.png`,
        color: '',
        value: 'metselwerk5',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.',
        component: <SimpleNumber value={0} onChange={() => console.log('house breedte')} min={0} max={1000} step={1}/>
    },
];

const roofTrimData = [
    {
        id: 1,
        name: 'Aluminium',
        imageUrl: `${process.env.PUBLIC_URL}/images/daktrim.png`,
        color: '',
        value: 'metselwerk6',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 2,
        name: 'Zwart',
        imageUrl: `${process.env.PUBLIC_URL}/images/daktrim-zwart.png`,
        color: '',
        value: 'metselwerk7',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
];

const OptionsTab: React.FC = () => {
    const {state, setGutter} = useConfiguration();

    const handleGutterToggle = (enabled: boolean) => {
        if (state.gutter.enabled === enabled) return;

        setGutter({...state.gutter, enabled});
    }

    const foundation = [
        <Option image={`${process.env.PUBLIC_URL}/images/betonpoeren.png`} name="Betonpoeren"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                price={100.99} components={[<RadioBlocks value={''} blocks={concretePiesData}
                                                         onChange={() => console.log('foundationBeamsData')}/>]}
                value={false} onChange={() => {
        }}
        />,
        <Option image={`${process.env.PUBLIC_URL}/images/fundering.png`} name="Funderingsbalken"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                price={100.99} components={[<RadioBlocks value={''} blocks={foundationBeamsData}
                                                         onChange={() => console.log('foundationBeamsData')}/>]}
                value={false} onChange={() => {
        }}
        />
    ];

    const roofing = [
        <Option image={`${process.env.PUBLIC_URL}/images/epdm.png`} name="EPDM"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                price={100.99} components={[]}
                value={false} onChange={() => {
        }}
        />,
        <Option image={`${process.env.PUBLIC_URL}/images/daktrim.png`} name="Daktrim"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                price={100.99} components={[<RadioBlocks value={''} blocks={roofTrimData}
                                                         onChange={() => console.log('foundationBeamsData')}/>]}
                value={false} onChange={() => {
        }}
        />,
        <Option image={`${process.env.PUBLIC_URL}/images/isolatieset.png`} name="Isolatieset"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                price={100.99} components={[]}
                value={false} onChange={() => {
        }}
        />,
    ];

    const other = [
        <Option image={`${process.env.PUBLIC_URL}/images/overige.png`} name="Hemelwaterafvoer"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                price={100.99} components={[]}
                value={state.gutter.enabled} onChange={handleGutterToggle}
        />,
    ];

    return (
        <div className="option-tab">
            <Option image={`${process.env.PUBLIC_URL}/images/fundering.png`} name="Fundering"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} components={foundation} value={false} onChange={() => {
            }}/>
            <Option image={`${process.env.PUBLIC_URL}/images/dakbedekking.png`} name="Dakbedekking"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} components={roofing} value={false} onChange={() => {
            }}/>
            {state.roofType !== 'platdak' &&
                <Option image={`${process.env.PUBLIC_URL}/images/overige.png`} name="Overige"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                        price={100.99} components={other} value={false} onChange={() => {
                }}/>}
        </div>
    );
};

export default OptionsTab;