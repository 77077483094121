import {Dimensions} from "src/types/configuration";

export const calculateHeight = (dimensions: Dimensions): number => {
    const angleRadians = (dimensions.angle * Math.PI) / 180;
    const halfBase = dimensions.depth / 2;
    const equalSide = dimensions.depth / (2 * Math.cos(angleRadians));
    const roofHeight = Math.sqrt(equalSide ** 2 - halfBase ** 2);

    return roofHeight + dimensions.height;
};
