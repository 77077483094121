import {useEffect} from 'react';
import RequestService from "src/services/requestService";
import {Configuration} from '../types/configuration';

export function useInitialConfiguration(request: RequestService, onInit: (config: Configuration) => void) {
    useEffect(() => {
        request.getOffer().then((offer) => {
            if (offer) {
                const loadedConfig = JSON.parse(atob(offer.configuration)) as Configuration;
                onInit(loadedConfig);
            }
        });
    }, [request, onInit]);
}