import React from 'react';
import RadioBlocks from "src/components/Input/RadioBlocks/RadioBlocks";
import SimpleRadioButtons from "src/components/Input/SimpleRadioButtons/SimpleRadioButtons";

const doors = [
    {
        id: 1,
        name: 'Douglas 6-ruits deur - enkel',
        imageUrl: `${process.env.PUBLIC_URL}/images/door1.png`,
        color: '',
        layout: 'in_value',
        value: 'enkel_6-ruits',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.',
    },
    {
        id: 2,
        name: 'Douglas 6-ruits deur - dubbel',
        imageUrl: `${process.env.PUBLIC_URL}/images/door1.png`,
        color: '',
        value: 'dubbel_dubbelglas',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 3,
        name: 'Douglas klampdeur - enkel',
        imageUrl: `${process.env.PUBLIC_URL}/images/door1.png`,
        color: '',
        value: 'enkel_klampdeur',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 4,
        name: 'Douglas klampdeur - dubbel',
        imageUrl: `${process.env.PUBLIC_URL}/images/door1.png`,
        color: '',
        value: 'dubbel_klampdeur',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 5,
        name: 'Douglas klampdeur XXL - enkel',
        imageUrl: `${process.env.PUBLIC_URL}/images/door2.png`,
        color: '',
        value: 'enkel_klampdeur_XXL',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 6,
        name: 'Deurmodule vuren modern zwart',
        imageUrl: `${process.env.PUBLIC_URL}/images/door2.png`,
        color: '',
        value: 'glaswand_modern_B450',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 7,
        name: 'Deurmodule & 2 x Glaswanden smal - vuren modern zwart',
        imageUrl: `${process.env.PUBLIC_URL}/images/door2.png`,
        color: '',
        value: 'glaswand_modern_B99',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 8,
        name: 'Deurmodule & 2 x Glaswanden groot - vuren modern zwart',
        imageUrl: `${process.env.PUBLIC_URL}/images/door2.png`,
        color: '',
        value: 'deurmodule',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
];

const sides = [
    {label: 'links-draaiend', value: 'left'},
    {label: 'rechts-draaiend', value: 'right'}
];

interface DoorSelectProps {
    id: string;
    value: string;
    subValue: string;
    onChange: (value: string) => void;
    onSubChange: (value: string) => void;
}

const DoorSelect: React.FC<DoorSelectProps> = ({id, value, subValue, onChange, onSubChange}) => {
    const doorsWithComponent = doors.map((door, index) => {
        if (index === 0) {
            return {
                ...door,
                component: (
                    <SimpleRadioButtons
                        name={`door-${id}`}
                        value={subValue}
                        values={sides}
                        onChange={onSubChange}
                    />
                )
            };
        }
        return door;
    });
    return (
        <>
            <RadioBlocks blocks={doorsWithComponent} value={value} onChange={onChange}/>
        </>
    )
};

export default DoorSelect;