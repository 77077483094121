import React from 'react';
import './LegendBox.css';
import {useView} from "src/contexts/ViewContext";
import {useConfiguration} from "src/contexts/ConfigurationContext";
import {calculateHeight} from "src/helpers/dimensions";

const LegendBox: React.FC = () => {
    const {isOptionVisible} = useView();
    const {state} = useConfiguration();

    return (
        <div className={`OptionBox ${isOptionVisible ? '' : 'hidden'}`}>
            <div className="optionsBoxBlock">
                <div className="optionsBoxHeader">Daklijn</div>
                <div className="optionBoxBullets">
                    <div className="optionBoxBullet"></div>
                    <div className="optionBoxBullet"></div>
                </div>

                <div className="optionsBoxTitle">Breedte</div>
                <div
                    className="optionsBoxValue">{state.dimensions.width + state.dimensions.overhangLeft + state.dimensions.overhangRight}</div>

                <div className="optionsBoxTitle">Diepte</div>
                <div
                    className="optionsBoxValue">{state.dimensions.depth + state.dimensions.overhangFront + state.dimensions.overhangBack}</div>
            </div>

            <div className="optionBoxSeparator"></div>

            <div className="optionsBoxBlock">
                <div className="optionsBoxHeader">Fundering</div>
                <div className="optionBoxBullets">
                    <div className="optionBoxBullet"></div>
                    <div className="optionBoxBullet"></div>
                </div>

                <div className="optionsBoxTitle">Breedte</div>
                <div className="optionsBoxValue">{state.dimensions.width}</div>

                <div className="optionsBoxTitle">Diepte</div>
                <div className="optionsBoxValue">{state.dimensions.depth}</div>
            </div>

            <div className="optionBoxSeparator"></div>

            <div className="optionsBoxBlock">
                <div className="optionsBoxHeader">Dakafmetingen</div>
                <div className="optionBoxBullets">
                    <div className="optionBoxBullet"></div>
                    <div className="optionBoxBullet"></div>
                </div>

                <div className="optionsBoxTitle">Dakhelling</div>
                <div className="optionsBoxValue">{state.roofType !== 'platdak' ? state.dimensions.angle : 0}deg</div>

                <div className="optionsBoxTitle">Nokhoogte</div>
                <div
                    className="optionsBoxValue">{state.roofType !== 'platdak' ? calculateHeight(state.dimensions).toFixed(0) : state.dimensions.height}</div>
            </div>
        </div>
    );
};

export default LegendBox;