import React, {useState, useEffect} from 'react';
import './ConstructionTab.css';
import Number from '../../Input/Number/Number';
import {useConfiguration} from "src/contexts/ConfigurationContext";
import RadioBlocks from "src/components/Input/RadioBlocks/RadioBlocks";
import Option from "src/components/Input/Option/Option";
import OptionText from "src/components/OptionText/OptionText";
// @ts-ignore
import isEqual from "lodash/isEqual";

const houseTypes = [
    {
        id: 1,
        name: 'Platdak',
        imageUrl: `${process.env.PUBLIC_URL}/images/platdak.png`,
        color: '',
        value: 'platdak',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 2,
        name: 'Zadeldak',
        imageUrl: `${process.env.PUBLIC_URL}/images/zadeldak.png`,
        color: '',
        value: 'zadeldak',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
];

const houtsoortBlocks = [
    {
        id: 1,
        name: 'Douglas geschaafd',
        imageUrl: `${process.env.PUBLIC_URL}/images/geschaafd.png`,
        color: '',
        value: 'douglas-geschaafd',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 2,
        name: 'Douglas fijnbezaagd',
        imageUrl: `${process.env.PUBLIC_URL}/images/fijnbezaagd.png`,
        color: '',
        value: 'douglas-fijnbezaagd',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 3,
        name: 'Red Class Wood',
        imageUrl: `${process.env.PUBLIC_URL}/images/red-class-wood.png`,
        color: '',
        value: 'red-class-wood',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
];

const houtsoortConstructie: React.ReactNode[] = [
    <RadioBlocks blocks={houtsoortBlocks} value={'douglas-geschaafd'} onChange={() => console.log('houtsoortBlocks')}/>
];

const ConstructionTab: React.FC = () => {
    const {state, setRoofType, setDimensions} = useConfiguration();
    const [width, setWidth] = useState(state.dimensions.width);
    const [depth, setDepth] = useState(state.dimensions.depth);
    const [overhangFront, setOverhangFront] = useState(state.dimensions.overhangFront);
    const [overhangBack, setOverhangBack] = useState(state.dimensions.overhangBack);
    const [overhangLeft, setOverhangLeft] = useState(state.dimensions.overhangLeft);
    const [overhangRight, setOverhangRight] = useState(state.dimensions.overhangRight);

    const kiesOverstekAfmetingen: React.ReactNode[] = [
        <Number label="Overstek links" value={state.dimensions.overhangLeft} onChange={setOverhangLeft} min={0}
                max={350}
                step={50}/>,
        <Number label="Overstek rechts" value={state.dimensions.overhangRight} onChange={setOverhangRight} min={0}
                max={350}
                step={50}/>,
        <Number label="Overstek voorzijde" value={state.dimensions.overhangFront} onChange={setOverhangFront} min={0}
                max={350}
                step={50}/>,
        <Number label="Overstek achterzijde" value={state.dimensions.overhangBack} onChange={setOverhangBack} min={0}
                max={350}
                step={50}/>,
    ];

    useEffect(() => {
        const updatedDimensions = {
            ...state.dimensions,
            width,
            depth,
            overhangFront,
            overhangLeft,
            overhangBack,
            overhangRight
        };

        if (isEqual(updatedDimensions, state.dimensions)) return;
        setDimensions(updatedDimensions);
    }, [width, depth, overhangFront, overhangLeft, overhangBack, overhangRight, setDimensions]);

    return (
        <div className="afmetingen-tab">
            <RadioBlocks blocks={houseTypes} value={state.roofType} onChange={setRoofType}/>
            <OptionText content="Kies uw afmetingen"/>
            <Number
                value={state.dimensions.width}
                onChange={setWidth}
                label="Breedte"
                min={3000}
                max={20000}
                step={500}
                subStep={100}
            />
            <Number
                value={state.dimensions.depth}
                onChange={setDepth}
                label="Diepte"
                min={3000}
                max={6000}
                step={500}
                subStep={100}
            />
            <Option image={`${process.env.PUBLIC_URL}/images/houtsoort.png`} name="Houtsoort constructie"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} components={houtsoortConstructie} value={false} onChange={() => {
            }}/>
            {(state.roofType === 'platdak') &&
                <Option image={`${process.env.PUBLIC_URL}/images/kies-overstek.png`} name="Kies overstek afmetingen"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                        price={100.99}
                        components={kiesOverstekAfmetingen} value={false} onChange={() => {
                }}/>}
        </div>
    );
};

export default ConstructionTab;