import React, {useState} from 'react';
import './WallSelector.css';
import {useConfiguration} from "src/contexts/ConfigurationContext";

interface WandSelectorProps {
    onWallSelect: (wall: UiWall | null) => void;
    activeWalls: string[];
}

export interface UiWall {
    id: string;
    type: 'horizontal' | 'vertical'; // 'horizontal' or 'vertical'
    row: number;
    column: number;
    active: boolean;
    selected: boolean;
    inner: boolean;
}

const getHorizontalWalls = (rows: number, columns: number, selectedWall: UiWall | null, activeWalls: string[]) => {
    // Генерация горизонтальных стен
    const horizontalWalls: UiWall[] = [];

    for (let r = 0; r <= rows; r++) {
        for (let c = 0; c < columns; c++) {
            const wallId = `h-${r}-${c}`;

            horizontalWalls.push({
                row: r,
                column: c,
                type: 'horizontal',
                id: wallId,
                selected: selectedWall?.id === wallId,
                active: activeWalls.includes(wallId),
                inner: !(r === 0 || r === rows)
            });
        }
    }

    return horizontalWalls;
};

const getVerticalWalls = (rows: number, columns: number, selectedWall: UiWall | null, activeWalls: string[]) => {
    // Генерация вертикальных стен
    const verticalWalls: UiWall[] = [];

    for (let r = 0; r < rows; r++) {
        for (let c = 0; c <= columns; c++) {
            const wallId = `v-${r}-${c}`;
            verticalWalls.push({
                row: r,
                column: c,
                type: 'vertical',
                id: wallId,
                selected: selectedWall?.id === wallId,
                active: activeWalls.includes(wallId),
                inner: !(c === 0 || c === columns)
            });
        }
    }

    return verticalWalls;
}

const generateBeams = (rows: number, columns: number) => {
    const beamsArray: { row: number; column: number; }[] = [];
    for (let r = 0; r <= rows; r++) {
        for (let c = 0; c <= columns; c++) {
            beamsArray.push({row: r, column: c});
        }
    }
    return beamsArray;
};

const WallSelector: React.FC<WandSelectorProps> = ({onWallSelect, activeWalls}) => {
    const {configurator} = useConfiguration();
    let rows = configurator.getRows();
    let columns = configurator.getColumns();

    const [selectedWall, setSelectedWall] = useState<UiWall | null>(null);
    const horizontalWalls = getHorizontalWalls(rows, columns, selectedWall, activeWalls);
    const verticalWalls = getVerticalWalls(rows, columns, selectedWall, activeWalls);
    const beams = generateBeams(rows, columns);

    const handleWallClick = (wall: UiWall | null) => {
        setSelectedWall((prevSelectedWall) => {
            if (prevSelectedWall?.id === wall?.id) {
                return null;
            }

            return wall;
        });
        onWallSelect(wall);
    };

    return (
        <div className="wand-selector">
            {Array.from({length: rows * 2 + 1}, (_, rowIndex) => {
                const rowElements = [];

                if (rowIndex % 2 === 0) {
                    // Рисуем балки и горизонтальные стены
                    for (let c = 0; c < columns; c++) {
                        const beam = beams.find(
                            (b) => b.row === rowIndex / 2 && b.column === c
                        );
                        const hWall = horizontalWalls.find(
                            (hw) => hw.row === rowIndex / 2 && hw.column === c
                        ) ?? null;

                        rowElements.push(
                            <div key={`beam-${beam?.row}-${beam?.column}`} className="beam"></div>
                        );

                        rowElements.push(
                            <div
                                key={hWall?.id}
                                className={`wall h-wall ${hWall?.selected ? 'selected' : ''} ${hWall?.active ? 'active' : ''}`}
                                onClick={() => handleWallClick(hWall)}
                            ></div>
                        );
                    }

                    // Add last beam in row
                    const beam = beams.find(
                        (b) => b.row === rowIndex / 2 && b.column === columns
                    );
                    rowElements.push(
                        <div key={`beam-${beam?.row}-${beam?.column}`} className="beam"></div>
                    );
                } else {
                    // Рисуем вертикальные стены и пространства
                    for (let c = 0; c <= columns; c++) {
                        const vWall = verticalWalls.find(
                            (vw) => vw.row === (rowIndex - 1) / 2 && vw.column === c
                        );

                        if (vWall) {
                            rowElements.push(
                                <div
                                    key={vWall.id}
                                    className={`wall v-wall ${vWall.selected ? 'selected' : ''} ${vWall?.active ? 'active' : ''} ${vWall.inner ? 'inner' : ''}`}
                                    onClick={() => handleWallClick(vWall)}
                                ></div>
                            );
                        }

                        if (c < columns) {
                            rowElements.push(
                                <div
                                    key={`space-${(rowIndex - 1) / 2}-${c}`}
                                    className="space"
                                ></div>
                            );
                        }
                    }
                }

                return (
                    <div key={`row-${rowIndex}`} className="row">
                        {rowElements}
                    </div>
                );
            })}
        </div>
    );
};

export default WallSelector;