import React, {useEffect, useState} from "react";
import './HorizontalSwitch.css';

interface HorizontalSwitchValuesProps {
    id: number;
    label: string;
    value: string;
}

interface HorizontalSwitchProps {
    value: string;
    values: HorizontalSwitchValuesProps[];
    onChange: (selectedValue: string) => void;
    className?: string;
}

const HorizontalSwitch: React.FC<HorizontalSwitchProps> = ({value, values, onChange, className}) => {
    const [activeValue, setActiveValue] = useState<string>(value);

    const handleItemClick = (value: string) => {
        setActiveValue(value);
    };

    useEffect(() => {
        onChange(activeValue);
    }, [activeValue, onChange]);

    return (
        <div className={`horizontal-switch ${className ?? ''}`}>
            {values.map(value => (
                <div key={value.id} className={`horizontal-switch-item ${activeValue === value.value ? 'select' : ''}`}
                     onClick={() => handleItemClick(value.value)}>
                    <span>{value.label}</span>
                </div>
            ))}
        </div>
    );
};

export default HorizontalSwitch;