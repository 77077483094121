import React, {useState, useMemo} from 'react';
import ConstructionTab from 'src/components/Tabs/ConstructionTab/ConstructionTab';
import WallsTab from 'src/components/Tabs/WallsTab/WallsTab';
import OptionsTab from 'src/components/Tabs/OptionsTab/OptionsTab';
import FinishTab from 'src/components/Tabs/FinishTab/FinishTab';
import MaterialsListTab from 'src/components/Tabs/MaterialsListTab/MaterialsListTab';
import './Tabs.css';
import StorageTab from "src/components/Tabs/StorageTab/StorageTab";
import {useView} from "src/contexts/ViewContext";

interface TabConfig {
    id: number;
    title: string;
    component: React.ReactNode;
}

interface TabProps {
    tab: {
        id: number;
        title: string;
        component: React.ReactNode;
    };
    isActive: boolean;
    onClick: () => void;
    onNext?: () => void;
    onPrev?: () => void;
    hidden: boolean;
    isCompleted: boolean;
}

const tabsData: TabConfig[] = [
    {
        id: 1,
        title: 'Constructie',
        component: <ConstructionTab/>
    },
    {
        id: 2,
        title: 'Berging',
        component: <StorageTab/>
    },
    {
        id: 3,
        title: 'Wanden',
        component: <WallsTab/>
    },
    {
        id: 4,
        title: 'Opties',
        component: <OptionsTab/>
    },
    {
        id: 5,
        title: 'Afwerking',
        component: <FinishTab/>
    },
    {
        id: 6,
        title: 'Materiaallijst',
        component: <MaterialsListTab/>
    }
];

const ArrowIcon: React.FC = () => (
    <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M22.2188 0.9375L23.7499 2.46878L12.1562 14.0625L0.5625 2.46878L2.09378 0.9375L12.1562 10.9999L22.2188 0.9375Z"/>
    </svg>
);

const PrevIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <g clipPath="url(#clip0_184_846)">
            <rect width="32" height="32" transform="matrix(-1 0 0 -1 32 32)" fill="#BFCA9F"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25 29.7001L22.9 31.7999L7 15.9L22.9 0L25 2.10004L11.2001 15.9L25 29.7001Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_184_846">
                <rect width="32" height="32" fill="white" transform="matrix(-1 0 0 -1 32 32)"/>
            </clipPath>
        </defs>
    </svg>
);

const NextIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <g clipPath="url(#clip0_184_838)">
            <rect width="32" height="32" fill="#768E32"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14 2.29993L16.1 0.200073L32 16.1L16.1 32L14 29.9L27.7999 16.1L14 2.29993Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_184_838">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const InfoIcon: React.FC = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98913 0.5 8 0.5ZM8 14.25C6.76387 14.25 5.5555 13.8834 4.52769 13.1967C3.49988 12.5099 2.6988 11.5338 2.22576 10.3918C1.75271 9.24973 1.62894 7.99307 1.8701 6.78069C2.11125 5.5683 2.70651 4.45466 3.58059 3.58058C4.45466 2.7065 5.56831 2.11125 6.78069 1.87009C7.99307 1.62893 9.24974 1.75271 10.3918 2.22575C11.5338 2.6988 12.5099 3.49988 13.1967 4.52769C13.8834 5.55549 14.25 6.76387 14.25 8C14.25 9.6576 13.5915 11.2473 12.4194 12.4194C11.2473 13.5915 9.65761 14.25 8 14.25Z"/>
        <path
            d="M7.99999 6.56254C7.83423 6.56254 7.67526 6.62838 7.55805 6.74559C7.44084 6.8628 7.37499 7.02178 7.37499 7.18754V11.4375C7.37499 11.6033 7.44084 11.7623 7.55805 11.8795C7.67526 11.9967 7.83423 12.0625 7.99999 12.0625C8.16575 12.0625 8.32472 11.9967 8.44193 11.8795C8.55914 11.7623 8.62499 11.6033 8.62499 11.4375V7.18754C8.62499 7.02178 8.55914 6.8628 8.44193 6.74559C8.32472 6.62838 8.16575 6.56254 7.99999 6.56254ZM7.99999 4.00004C7.8308 4.0064 7.67046 4.0773 7.5519 4.19818C7.43334 4.31906 7.36557 4.48075 7.36249 4.65004V4.75004C7.3622 4.83158 7.37888 4.91229 7.41148 4.98704C7.44408 5.06179 7.49188 5.12893 7.55185 5.18419C7.61182 5.23945 7.68263 5.28163 7.75978 5.30803C7.83694 5.33443 7.91874 5.34448 7.99999 5.33754C8.16378 5.33135 8.31918 5.26351 8.43507 5.14762C8.55097 5.03172 8.6188 4.87632 8.62499 4.71254V4.56254C8.62548 4.4831 8.60915 4.40446 8.57705 4.3318C8.54496 4.25913 8.49784 4.19409 8.4388 4.14095C8.37975 4.08781 8.31012 4.04778 8.23449 4.02349C8.15885 3.9992 8.07894 3.99121 7.99999 4.00004Z"/>
    </svg>
);

const CheckIcon: React.FC = () => (
    <span>✓</span>
);

const Tab: React.FC<TabProps> = ({tab, isActive, onClick, hidden, onNext, onPrev, isCompleted}) => {
    if (hidden) return null;

    return (
        <div className="tab">
            <div className={`tab-header ${isActive ? 'open' : ''}`} onClick={onClick}>
                <span>
                    {isCompleted && <CheckIcon/>}
                    {!isCompleted && <InfoIcon/>}
                    {tab.title}
                </span>
                <ArrowIcon/>
            </div>
            {isActive && (
                <div className="tab-content">
                    {tab.component}
                    <div className="tab-navigation">
                        {onPrev && <button className={'prevButton'} onClick={onPrev}>
                            <PrevIcon/>
                        </button>}
                        {onNext && <button className={'nextButton'} onClick={onNext}>
                            Volgende
                            <NextIcon/>
                        </button>}
                    </div>
                </div>
            )}
        </div>
    )
};

const Tabs: React.FC = () => {
    const [activeTab, setActiveTab] = useState<number | null>(null);
    const [completedTabs, setCompletedTabs] = useState<Set<number>>(new Set());
    const {userRole} = useView();

    const visibleTabs = useMemo(() => {
        return tabsData.filter(tab => {
            return !(userRole !== 'admin' && tab.id === 6);
        });
    }, [userRole]);

    const handleTabClick = (id: number) => {
        setActiveTab(prevActive => {
            const newActiveTab = (prevActive === id) ? null : id;
            if (newActiveTab !== null && completedTabs.has(newActiveTab)) {
                const newSet = new Set(completedTabs);
                newSet.delete(newActiveTab);
                setCompletedTabs(newSet);
            }
            return newActiveTab;
        });
    };

    const goToNextTab = () => {
        if (activeTab === null) return;
        const currentIndex = visibleTabs.findIndex(t => t.id === activeTab);
        if (currentIndex < 0) return;
        const nextTab = visibleTabs[currentIndex + 1];

        if (nextTab) {
            setCompletedTabs(prev => {
                const newSet = new Set(prev);
                newSet.add(activeTab);
                return newSet;
            });

            setActiveTab(nextTab.id);

            if (completedTabs.has(nextTab.id)) {
                const newSet = new Set(completedTabs);
                newSet.delete(nextTab.id);
                setCompletedTabs(newSet);
            }
        }
    };

    const goToPrevTab = () => {
        if (activeTab === null) return;
        const currentIndex = visibleTabs.findIndex(t => t.id === activeTab);
        if (currentIndex < 0) return;
        const prevTab = visibleTabs[currentIndex - 1];

        if (prevTab) {
            setActiveTab(prevTab.id);

            if (completedTabs.has(prevTab.id)) {
                const newSet = new Set(completedTabs);
                newSet.delete(prevTab.id);
                setCompletedTabs(newSet);
            }
        }
    };

    return (
        <div className="tabs-container">
            {visibleTabs.map((tab, index) => {
                const isActive = activeTab === tab.id;
                const isCompleted = completedTabs.has(tab.id);
                const hasPrev = index > 0 && isActive;
                const hasNext = index < visibleTabs.length - 1 && isActive;

                return (
                    <Tab
                        key={tab.id}
                        tab={tab}
                        isActive={isActive}
                        onClick={() => handleTabClick(tab.id)}
                        hidden={false}
                        onNext={isActive && hasNext ? goToNextTab : undefined}
                        onPrev={isActive && hasPrev ? goToPrevTab : undefined}
                        isCompleted={isCompleted}
                    />
                );
            })}
        </div>
    );
};

export default Tabs;
