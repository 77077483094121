import React from 'react';
import './Scene.css';
import LeftBar from "src/components/LeftBar/LeftBar";
import {useView} from "src/contexts/ViewContext";

const Scene: React.FC = () => {
    const {is3DView} = useView();

    return (
        <div id="configurator-scene" className={is3DView ? '' : 'map'}>
            <LeftBar/>
            <div id="canvas-loader"></div>
        </div>
    );
};

export default Scene;