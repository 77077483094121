import React from 'react';
import RadioBlocks from "src/components/Input/RadioBlocks/RadioBlocks";
import HorizontalTabs from "src/components/HorizontalTabs/HorizontalTabs";
import ColorTab from "src/components/Tabs/WallsTab/components/ColorTab";
import HorizontalSwitch from "src/components/Input/HorizontalSwitch/HorizontalSwitch";
import {Wall} from "src/types/configuration";

const elements = [
    {
        id: 1,
        name: 'Houten shutter',
        imageUrl: `${process.env.PUBLIC_URL}/images/shutter.png`,
        color: '',
        value: 'zhaluzi',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 2,
        name: 'Glasswand smal - vuren modern zwart',
        imageUrl: `${process.env.PUBLIC_URL}/images/glasswand1.png`,
        color: '',
        value: 'glass_1m',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 3,
        name: 'Glasswand groot - vuren modern zwart',
        imageUrl: `${process.env.PUBLIC_URL}/images/glasswand2.png`,
        color: '',
        value: 'glass_2m',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
];

const sideSwitchData = [
    {id: 1, label: 'Plats vanaf links', value: 'left'},
    {id: 2, label: 'Plaats vanaf rechts', value: 'right'},
];

interface ElementTabProps {
    wall: Wall;
    onElement1Change: (value: string) => void;
    onElement2Change: (value: string) => void;
    onElement3Change: (value: string) => void;
    onElementSideChange: (value: string) => void;
    onSetOuterColor: (value: string) => void;
    onSetInnerColor: (value: string) => void;
    onSetInnerColorEnable: (value: boolean) => void;
}

const ElementTab: React.FC<ElementTabProps> = ({
                                                   wall,
                                                   onElementSideChange,
                                                   onElement1Change,
                                                   onElement2Change,
                                                   onElement3Change,
                                                   onSetOuterColor,
                                                   onSetInnerColor,
                                                   onSetInnerColorEnable
                                               }) => {
    const elementsTabs = [
        {
            id: 1,
            title: 'Element 1',
            component: <RadioBlocks blocks={elements} value={wall.elementWall.elements[0] ?? ''}
                                    onChange={onElement1Change}/>
        },
        {
            id: 2,
            title: 'Element 2',
            component: <RadioBlocks blocks={elements} value={wall.elementWall.elements[1] ?? ''}
                                    onChange={onElement2Change}/>
        },
        {
            id: 3,
            title: 'Element 3',
            component: <RadioBlocks blocks={elements} value={wall.elementWall.elements[2] ?? ''}
                                    onChange={onElement3Change}/>
        },
    ];

    const elementOptionsTabs = [
        {id: 1, title: 'Elementen', component: <HorizontalTabs tabs={elementsTabs} key={'elements-tabs'}/>},
        {
            id: 2,
            title: 'Wand',
            component: <ColorTab key={'wall-element-color-type'} parent="elements-tab" wall={wall}
                                 onSetInnerColor={onSetInnerColor}
                                 onSetOuterColor={onSetOuterColor} onSetInnerColorEnable={onSetInnerColorEnable}/>
        },
    ]

    return (
        <>
            <HorizontalSwitch value={wall.elementWall.side} values={sideSwitchData} onChange={onElementSideChange}/>
            <HorizontalTabs tabs={elementOptionsTabs} key={'element-options-tabs'}/>
        </>
    );
}

export default ElementTab;