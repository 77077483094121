import React from 'react';
import './StorageTab.css';
import Option from "src/components/Input/Option/Option";
import HorizontalTabs from "src/components/HorizontalTabs/HorizontalTabs";
import ColorTab from "src/components/Tabs/StorageTab/ColorTab";
import DoorWindowTab from "src/components/Tabs/StorageTab/DoorWindowTab";
import {useConfiguration} from "src/contexts/ConfigurationContext";

const StorageTab: React.FC = () => {
    const {state, setStorages} = useConfiguration();

    const handleLeftStorageEnable = () => {
        setStorages({left: !state.storages.left, right: state.storages.right});
    };

    const handleRightStorageEnable = () => {
        setStorages({left: state.storages.left, right: !state.storages.right});
    };

    const leftHouseTabs = [
        {id: 1, title: 'Houtsoort wand', component: <ColorTab storage={'left'} key={'left-house-wood-type'}/>},
        {id: 2, title: 'Deur en raam', component: <DoorWindowTab key={'left-house-door-window'}/>},
    ];

    const rightHouseTabs = [
        {id: 1, title: 'Houtsoort wand', component: <ColorTab storage={'right'} key={'right-house-wood-type'}/>},
        {id: 2, title: 'Deur en raam', component: <DoorWindowTab key={'right-house-door-window'}/>},
    ];

    return (
        <div className="house-tab">
            <Option key={'left-house'} image={`${process.env.PUBLIC_URL}/images/house-left.png`} name="Berging links"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} components={[<HorizontalTabs tabs={leftHouseTabs} key={'left-house-tabs'}/>]}
                    value={state.storages.left} onChange={handleLeftStorageEnable}
            />
            <Option key={'right-house'} image={`${process.env.PUBLIC_URL}/images/house-right.png`} name="Berging rechts"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99}
                    components={[<HorizontalTabs tabs={rightHouseTabs} key={'right-house-tabs'}/>]}
                    value={state.storages.right} onChange={handleRightStorageEnable}
            />
        </div>
    );
};

export default StorageTab;