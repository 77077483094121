import React, {createContext, useContext, ReactNode, useCallback} from "react";
import {Configuration, Wall} from "../types/configuration";

import useConfiguratorService from '../hooks/useConfiguratorService';
import useConfigurationService from "src/hooks/useConfigurationService";
import useCalculationService from "src/hooks/useCalculationService";
import useRequestService from "src/hooks/useRequestService";
import {useConfigurationState} from "../hooks/useConfigurationState";
import {useInitialConfiguration} from "../hooks/useInitialConfiguration";
import {useSynchronizeServices} from "../hooks/useSynchronizeServices";

interface ConfigurationContextProps {
    state: Configuration;
    setRoofType: (roofType: string) => void;
    setDimensions: (dims: Configuration['dimensions']) => void;
    setParapet: (parapet: Configuration['parapet']) => void;
    setRoof: (roof: Configuration['roof']) => void;
    setGutter: (gutter: Configuration['gutter']) => void;
    setWalls: (walls: Configuration['walls']) => void;
    setStorages: (storages: Configuration['storages']) => void;
    addWall: (wall: Wall) => void;
    configurator: any;
    configuration: any;
    calculation: any;
    request: any;
}

const ConfigurationContext = createContext<ConfigurationContextProps | undefined>(undefined);

export const ConfigurationProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const {state, dispatch} = useConfigurationState();

    const configurator = useConfiguratorService();
    const configuration = useConfigurationService(state, configurator);
    const calculation = useCalculationService(state, configurator);
    const request = useRequestService(configuration, configurator);

    useInitialConfiguration(request, (loadedConfig) => {
        dispatch({type: 'SET_CONFIGURATION', payload: loadedConfig});
    });

    const setRoofType = (roofType: string) => dispatch({type: 'SET_ROOF_TYPE', payload: roofType});
    const setDimensions = (dimensions: Configuration['dimensions']) => dispatch({
        type: 'SET_DIMENSIONS',
        payload: dimensions
    });
    const setParapet = (parapet: Configuration['parapet']) => dispatch({type: 'SET_PARAPET', payload: parapet});
    const setRoof = (roof: Configuration['roof']) => dispatch({type: 'SET_ROOF', payload: roof});
    const setGutter = (gutter: Configuration['gutter']) => dispatch({type: 'SET_GUTTER', payload: gutter});
    const setWalls = (walls: Configuration['walls']) => dispatch({type: 'SET_WALLS', payload: walls});
    const setStorages = (storages: Configuration['storages']) => dispatch({type: 'SET_STORAGES', payload: storages});

    const addWall = useCallback((wall: Wall) => {
        const wallExists = state.walls.some(w => w.id === wall.id);

        if (wallExists) {
            return setWalls(state.walls.map(w => (w.id === wall.id ? wall : w)));
        } else {
            return setWalls([...state.walls, wall]);
        }
    }, [state.walls]);

    useSynchronizeServices(state, configuration, calculation, configurator, setWalls);

    return (
        <ConfigurationContext.Provider
            value={{
                state,
                setRoofType,
                setDimensions,
                setParapet,
                setRoof,
                setGutter,
                setWalls,
                setStorages,
                addWall,
                configurator,
                configuration,
                calculation,
                request
            }}
        >
            {children}
        </ConfigurationContext.Provider>
    );
};

export const useConfiguration = () => {
    const context = useContext(ConfigurationContext);
    if (!context) {
        throw new Error("useConfiguration must be used within a ConfigurationProvider");
    }
    return context;
};