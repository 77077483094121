import React from 'react';
import RadioBlocks from "src/components/Input/RadioBlocks/RadioBlocks";
import SimpleRadioButtons from "src/components/Input/SimpleRadioButtons/SimpleRadioButtons";

const windows = [
    {
        id: 1,
        name: 'Douglas 6-ruits uitzetraam, enkel',
        imageUrl: `${process.env.PUBLIC_URL}/images/window1.png`,
        color: '',
        value: 'uitzetraam_6ruits',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 2,
        name: 'Douglas 9-ruits uitzetraam',
        imageUrl: `${process.env.PUBLIC_URL}/images/window1.png`,
        color: '',
        value: 'uitzetraam_9ruits',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 3,
        name: 'Douglas 6-ruits uitzetraam, dubbel',
        imageUrl: `${process.env.PUBLIC_URL}/images/window1.png`,
        color: '',
        value: 'vast_raam_enkel',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 4,
        name: 'Douglas 4-vaks draairaam, enkel',
        imageUrl: `${process.env.PUBLIC_URL}/images/window1.png`,
        color: '',
        value: 'dubbel_draairaam',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 5,
        name: 'Douglas 4-vaks vast raam, enkel',
        imageUrl: `${process.env.PUBLIC_URL}/images/window1.png`,
        color: '',
        value: 'enkel_deluxe',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
];

interface WindowSelectProps {
    value: string;
    onChange: (value: string) => void;
}

const WindowSelect: React.FC<WindowSelectProps> = ({value, onChange}) => {
    return (
        <>
            <RadioBlocks blocks={windows} value={value} onChange={onChange}/>
        </>
    )
};

export default WindowSelect;