import React, {useState, useEffect} from 'react';
import './Number.css';

interface NumberInputProps {
    value: number;
    onChange: (value: number) => void;
    min: number;
    max: number;
    step: number;
    label?: string;
    showSubLabel?: boolean;
    subStep?: number;
}

const NumberInput: React.FC<NumberInputProps> = ({
                                                     value,
                                                     onChange,
                                                     min,
                                                     max,
                                                     step,
                                                     label,
                                                     showSubLabel = true,
                                                     subStep
                                                 }) => {
    const [inputValue, setInputValue] = useState(value);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

    const roundValue = (num: number, numStep: number) => {
        if (min !== undefined && num < min) {
            num = min;
        } else if (max !== undefined && num > max) {
            num = max;
        }
        num = Math.round(num / numStep) * numStep;

        return num;
    };

    const handleMinusClick = () => {
        const roundedValue = roundValue(inputValue - step, step);
        setInputValue(roundedValue);
        onChange(roundedValue);
    };

    const handlePlusClick = () => {
        const roundedValue = roundValue(inputValue + step, step);
        setInputValue(roundedValue);
        onChange(roundedValue);
    };

    const handleSubMinusClick = () => {
        const stepTemp = subStep ?? step;
        const roundedValue = roundValue(inputValue - stepTemp, stepTemp);
        setInputValue(roundedValue);
        onChange(roundedValue);
    };

    const handleSubPlusClick = () => {
        const stepTemp = subStep ?? step;
        const roundedValue = roundValue(inputValue + stepTemp, stepTemp);
        setInputValue(roundedValue);
        onChange(roundedValue);
    };

    const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10) || 0;
        setInputValue(value);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(() => {
                const roundedValue = roundValue(value, subStep ?? step);
                setInputValue(roundedValue);
                onChange(roundedValue);
            }, 600)
        );
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <div className="number-input">
            <div className="number-input__label">
                {label && <span>{label}</span>}
                {showSubLabel && <span>(min {min} - max {max})</span>}
            </div>

            <div className="number-input__wrapper">
                {subStep && <button
                    className="number-input__minus--sub"
                    onClick={handleSubMinusClick}
                >–
                </button>}
                <button
                    className="number-input__minus"
                    onClick={handleMinusClick}
                >–
                </button>
                <div className="number-input__input">
                    <input
                        type="number"
                        value={inputValue}
                        onChange={handleInputValue}
                        min={min}
                        max={max}
                        step={step}
                    />
                    <span className="number-input__unit">mm</span>
                </div>

                <button
                    className="number-input__plus"
                    onClick={handlePlusClick}
                >+
                </button>

                {subStep && <button
                    className="number-input__plus--sub"
                    onClick={handleSubPlusClick}
                >+
                </button>}
            </div>
        </div>
    );
}

export default NumberInput;