import React from 'react';
import './DataTable.css';
import prices from "../../prices";
import useCalculationService from "src/hooks/useCalculationService";
import {useConfiguration} from "src/contexts/ConfigurationContext";

const DataTable: React.FC = () => {
    const {calculation} = useConfiguration();

    return (
        <div className="data-table">
            <table>
                <tbody>
                {calculation.calculateConfiguration(prices).map((row: {
                    label: string;
                    unit: string;
                    quantity: number;
                    price: number;
                    total: number;
                }, index: number) => (
                    <tr key={index}>
                        <td>{row.label}</td>
                        <td>{row.unit}</td>
                        <td>{row.quantity}</td>
                        <td>{calculation.formatToCurrency(row.price)}</td>
                        <td>{calculation.formatToCurrency(row.total)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="total-price">
                <span>Totaal Prijs:</span>
                <span>{calculation.getFormatTotalPrice()}</span>
            </div>
        </div>
    );
};

export default DataTable;