import React, {useState, useEffect} from 'react';
import './RadioBlocks.css';
import {toEUR} from "src/helpers/calculation";

type RadioBlockProps = {
    block: RadioBlockInterface;
    isSelected: boolean;
    isDisabled: boolean;
    onClick: (value: string) => void;
};

interface RadioBlockInterface {
    id: number;
    name: string;
    imageUrl: string;
    color: string;
    value: string;
    price: number;
    description: string;
    component?: React.ReactNode;
    layout?: string; // after or in_value
}

interface RadioBlocksProps {
    blocks: RadioBlockInterface[];
    label?: string;
    value: string;
    disableValues?: string[];
    className?: string;
    onChange: (selectedValue: string) => void;
}

const RadioBlock: React.FC<RadioBlockProps> = ({block, isSelected, isDisabled, onClick}) => {
    const [showDetails, setShowDetails] = React.useState(false);

    return (
        <div key={block.id}
             className={`radio-block ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''} ${block.layout === 'in_value' ? 'in_value' : ''}`}
             data-value={block.value}
             onClick={() => !isDisabled && onClick(block.value)}>
            <div className="icon" style={{backgroundColor: block.color}}>
                {block.imageUrl && <img src={block.imageUrl} alt={block.name}/>}
            </div>
            <div className="name">{block.name}</div>
            {block.price && <div className="price">{toEUR(block.price)}</div>}
            {(block.component && block.layout === 'in_value') &&
                <div className="value">
                    <div className="component">
                        {block.component}
                    </div>
                </div>}
            {block.description && <div className="details" onClick={(event) => {
                setShowDetails(!showDetails);
                event.stopPropagation();
            }}>
                <span>Details</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 16L18 10L16.59 8.59L12 13.17L7.41 8.59L6 10L12 16Z" fill="#170E0E"/>
                </svg>
            </div>}
            {(block.description && showDetails) && <div className="description">
                {block.description}
            </div>}
            {(block.component && block.layout !== 'in_value') && <div className="component">
                {block.component}
            </div>}
        </div>
    );
};

const RadioBlocks: React.FC<RadioBlocksProps> = ({
                                                     blocks,
                                                     label,
                                                     value,
                                                     disableValues = [],
                                                     className,
                                                     onChange
                                                 }) => {
    const [selectedValue, setSelectedValue] = useState(value);

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const handleBlockClick = (newValue: string) => {
        if (!disableValues.includes(newValue)) {
            setSelectedValue(newValue);
            onChange(newValue);
        }
    };

    return (
        <div className={`radio-blocks ${className || ''}`}>
            {label && <div className="radio-blocks-label">{label}</div>}
            {blocks.map((block) => (
                <RadioBlock
                    key={block.id}
                    block={block}
                    isSelected={block.value === selectedValue}
                    isDisabled={disableValues.includes(block.value)}
                    onClick={handleBlockClick}
                />
            ))}
        </div>
    );
};

export default RadioBlocks;