import React, {useState} from 'react';
import Tabs from "./components/Tabs/Tabs";
import './App.css';
import LegendBox from "src/components/LegendBox/LegendBox";
import Scene from "./components/Scene/Scene";
import Loader from "./components/Loader/Loader";
import {useWindowResize} from "src/hooks/useWindowResize";
import {useView} from "src/contexts/ViewContext";
import {useConfiguration} from "src/contexts/ConfigurationContext";
import SaveOfferForm from "src/components/SaveOfferForm/SaveOfferForm";
import ResultPopup from "src/components/ResultPopup/ResultPopup";
import TotalPrice from "src/components/TotalPrice/TotalPrice";

const App: React.FC = () => {
    const [showForm, setShowForm] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const windowWidth = useWindowResize();
    const {userRole} = useView();
    const {request} = useConfiguration();

    const openForm = () => {
        setShowForm(!showForm);
    }

    const addOffer = () => {
        request.addOrder();
    }

    const saveOffer = () => {
        request.saveOffer().then(() => {
            console.log('Offer saved');
            setShowSuccessPopup(true);
        });
    }

    return (
        <>
            <Loader/>
            <Scene/>
            <div className={`configurator-ui-wrapper`}>
                {windowWidth > 768 && <>
                    <div></div>
                    <div></div>
                </>}
                {windowWidth <= 768 && <LegendBox/>}
                <div id="configurator-ui">
                    <Tabs/>
                    <TotalPrice/>
                    {userRole === 'client' && <button className="save-button" onClick={openForm}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28"
                             fill="none">
                            <mask id="mask0_14_3257" maskUnits="userSpaceOnUse" x="0"
                                  y="17" width="26" height="11">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M0.712891 17.0334H25.2872V27.0176H0.712891V17.0334Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_14_3257)">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M1.77194 17.0334C1.18786 17.0334 0.712891 17.5084 0.712891 18.0925V23.6773C0.712891 25.5191 2.21143 27.0176 4.05323 27.0176H21.947C23.7888 27.0176 25.2873 25.5191 25.2873 23.6773V18.0925C25.2873 17.5084 24.8124 17.0334 24.2283 17.0334C23.6442 17.0334 23.1692 17.5084 23.1692 18.0925V23.6773C23.1692 24.351 22.6207 24.8996 21.947 24.8996H4.05323C3.37957 24.8996 2.83098 24.351 2.83098 23.6773V18.0925C2.83098 17.5084 2.35601 17.0334 1.77194 17.0334Z"
                                      fill="white"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M7.43163 12.701C7.02539 12.701 6.65641 12.9409 6.49144 13.3121C6.32646 13.6833 6.39564 14.1179 6.6675 14.4195L12.2364 20.5902C12.6257 21.0221 13.3748 21.0221 13.7642 20.5902L19.333 14.4195C19.6049 14.1179 19.6741 13.6833 19.5091 13.3121C19.3441 12.9409 18.9751 12.701 18.5689 12.701H14.6498V8.29007C14.6498 7.38048 13.9096 6.64075 13.0005 6.64075C12.0909 6.64075 11.3507 7.38048 11.3507 8.29007V12.701H7.43163Z"
                                  fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M12.9994 0.982422C12.0898 0.982422 11.3501 1.72216 11.3501 2.63175V2.89252C11.3501 3.80166 12.0898 4.54184 12.9994 4.54184C13.909 4.54184 14.6487 3.80166 14.6487 2.89252V2.63175C14.6487 1.72216 13.909 0.982422 12.9994 0.982422Z"
                                  fill="white"/>
                        </svg>
                        <span>Offerte opslaan</span>
                    </button>}
                    {userRole !== 'client' && <button className="save-button" onClick={addOffer}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28"
                             fill="none">
                            <mask id="mask0_14_3257" maskUnits="userSpaceOnUse" x="0"
                                  y="17" width="26" height="11">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M0.712891 17.0334H25.2872V27.0176H0.712891V17.0334Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_14_3257)">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M1.77194 17.0334C1.18786 17.0334 0.712891 17.5084 0.712891 18.0925V23.6773C0.712891 25.5191 2.21143 27.0176 4.05323 27.0176H21.947C23.7888 27.0176 25.2873 25.5191 25.2873 23.6773V18.0925C25.2873 17.5084 24.8124 17.0334 24.2283 17.0334C23.6442 17.0334 23.1692 17.5084 23.1692 18.0925V23.6773C23.1692 24.351 22.6207 24.8996 21.947 24.8996H4.05323C3.37957 24.8996 2.83098 24.351 2.83098 23.6773V18.0925C2.83098 17.5084 2.35601 17.0334 1.77194 17.0334Z"
                                      fill="white"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M7.43163 12.701C7.02539 12.701 6.65641 12.9409 6.49144 13.3121C6.32646 13.6833 6.39564 14.1179 6.6675 14.4195L12.2364 20.5902C12.6257 21.0221 13.3748 21.0221 13.7642 20.5902L19.333 14.4195C19.6049 14.1179 19.6741 13.6833 19.5091 13.3121C19.3441 12.9409 18.9751 12.701 18.5689 12.701H14.6498V8.29007C14.6498 7.38048 13.9096 6.64075 13.0005 6.64075C12.0909 6.64075 11.3507 7.38048 11.3507 8.29007V12.701H7.43163Z"
                                  fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M12.9994 0.982422C12.0898 0.982422 11.3501 1.72216 11.3501 2.63175V2.89252C11.3501 3.80166 12.0898 4.54184 12.9994 4.54184C13.909 4.54184 14.6487 3.80166 14.6487 2.89252V2.63175C14.6487 1.72216 13.909 0.982422 12.9994 0.982422Z"
                                  fill="white"/>
                        </svg>
                        <span>In winkelwagen</span>
                    </button>
                    }
                    {userRole !== 'client' && <button className="save-button" onClick={saveOffer}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28"
                             fill="none">
                            <mask id="mask0_14_3257" maskUnits="userSpaceOnUse" x="0"
                                  y="17" width="26" height="11">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M0.712891 17.0334H25.2872V27.0176H0.712891V17.0334Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_14_3257)">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M1.77194 17.0334C1.18786 17.0334 0.712891 17.5084 0.712891 18.0925V23.6773C0.712891 25.5191 2.21143 27.0176 4.05323 27.0176H21.947C23.7888 27.0176 25.2873 25.5191 25.2873 23.6773V18.0925C25.2873 17.5084 24.8124 17.0334 24.2283 17.0334C23.6442 17.0334 23.1692 17.5084 23.1692 18.0925V23.6773C23.1692 24.351 22.6207 24.8996 21.947 24.8996H4.05323C3.37957 24.8996 2.83098 24.351 2.83098 23.6773V18.0925C2.83098 17.5084 2.35601 17.0334 1.77194 17.0334Z"
                                      fill="white"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M7.43163 12.701C7.02539 12.701 6.65641 12.9409 6.49144 13.3121C6.32646 13.6833 6.39564 14.1179 6.6675 14.4195L12.2364 20.5902C12.6257 21.0221 13.3748 21.0221 13.7642 20.5902L19.333 14.4195C19.6049 14.1179 19.6741 13.6833 19.5091 13.3121C19.3441 12.9409 18.9751 12.701 18.5689 12.701H14.6498V8.29007C14.6498 7.38048 13.9096 6.64075 13.0005 6.64075C12.0909 6.64075 11.3507 7.38048 11.3507 8.29007V12.701H7.43163Z"
                                  fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M12.9994 0.982422C12.0898 0.982422 11.3501 1.72216 11.3501 2.63175V2.89252C11.3501 3.80166 12.0898 4.54184 12.9994 4.54184C13.909 4.54184 14.6487 3.80166 14.6487 2.89252V2.63175C14.6487 1.72216 13.909 0.982422 12.9994 0.982422Z"
                                  fill="white"/>
                        </svg>
                        <span>Spaar aanbieding</span>
                    </button>
                    }
                </div>
                {windowWidth > 768 && <LegendBox/>}
            </div>
            {(userRole === 'client' && showForm) &&
                <SaveOfferForm showForm={showForm} setShowForm={setShowForm}
                               setShowSuccessPopup={setShowSuccessPopup}/>}
            {(userRole === 'client' && showSuccessPopup) &&
                <ResultPopup header="Thank you for your offer!" message="We will contact you as soon as possible."
                             setShowSuccessPopup={setShowSuccessPopup}/>}
            {(userRole !== 'client' && showSuccessPopup) &&
                <ResultPopup header="Offer updated!" message="" setShowSuccessPopup={setShowSuccessPopup}/>}
        </>
    );
}

export default App;
