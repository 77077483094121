import React, {useState, useCallback, useEffect} from 'react';
import './WallsTab.css';
import WallSelector, {UiWall} from "src/components/Tabs/WallsTab/components/WallSelector";
import ColorTab from "src/components/Tabs/WallsTab/components/ColorTab";
import {useConfiguration} from "src/contexts/ConfigurationContext";
import Option from "src/components/Input/Option/Option";
import HorizontalTabs from "src/components/HorizontalTabs/HorizontalTabs";
import DoorWindowTab from "src/components/Tabs/WallsTab/components/DoorWindowTab";
import ElementTab from "src/components/Tabs/WallsTab/components/ElementTab";
import {ElementWall, Wall, WoodWall} from "src/types/configuration";

const WallsTab: React.FC = () => {
    const {state, addWall, configuration, configurator} = useConfiguration();
    const [selectedWall, setSelectedWall] = useState<UiWall | null>(null);
    const [activeWalls, setActiveWalls] = useState<string[]>([]);
    const [woodenWall, setWoodenWall] = useState<boolean>(false);
    const [individualElements, setIndividualElements] = useState<boolean>(false);
    const [wall, setWall] = useState<Wall>(configuration.findWall(selectedWall));

    const handleChangeDoor = useCallback((door: string) => {
        if (wall.woodWall.door === door) {
            return;
        }
        const woodWall = {...wall.woodWall, door};
        const elementWall = configuration.getDefaultElementWall();
        const updatedWall = {...wall, woodWall, elementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleChangeWindow = useCallback((window: string) => {
        if (wall.woodWall.window === window) {
            return;
        }

        const woodWall = {...wall.woodWall, window};
        const elementWall = configuration.getDefaultElementWall();
        const updatedWall = {...wall, woodWall, elementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleDoorTurningChange = useCallback((doorSide: string) => {
        if (wall.woodWall.doorSide === doorSide) {
            return;
        }

        const woodWall: WoodWall = {...wall.woodWall, doorSide};
        const elementWall = configuration.getDefaultElementWall();
        const updatedWall = {...wall, woodWall, elementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleDoorWindowSideChange = useCallback((side: string) => {
        if (wall.woodWall.side === side) {
            return;
        }

        // @ts-ignore
        const woodWall: WoodWall = {...wall.woodWall, side};
        const elementWall = configuration.getDefaultElementWall();
        const updatedWall = {...wall, woodWall, elementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleInnerColorChange = useCallback((innerColor: string) => {
        if (wall.woodWall.innerColor === innerColor) {
            return;
        }

        const woodWall = {...wall.woodWall, innerColor};
        const elementWall = configuration.getDefaultElementWall();
        const updatedWall = {...wall, woodWall, elementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleOuterColorChange = useCallback((outerColor: string) => {
        if (wall.woodWall.outerColor === outerColor) {
            return;
        }

        const woodWall = {...wall.woodWall, outerColor};
        const elementWall = configuration.getDefaultElementWall();
        const updatedWall = {...wall, woodWall, elementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleInnerColorEnableChange = useCallback((innerEnable: boolean) => {
        if (wall.woodWall.innerEnable === innerEnable) {
            return;
        }

        const woodWall = {...wall.woodWall, innerEnable};
        const elementWall = configuration.getDefaultElementWall();
        const updatedWall = {...wall, woodWall, elementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleElementInnerColorChange = useCallback((elementInnerColor: string) => {
        if (wall.elementWall.innerColor === elementInnerColor) {
            return;
        }

        const updatedElementWall = {...wall.elementWall, innerColor: elementInnerColor};
        const woodWall = configuration.getDefaultWoodWall();
        const updatedWall = {...wall, woodWall, elementWall: updatedElementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleElementInnerEnableChange = useCallback((elementInnerEnable: boolean) => {
        if (wall.woodWall.innerEnable === elementInnerEnable) {
            return;
        }

        const updatedElementWall = {...wall.elementWall, innerEnable: elementInnerEnable};
        const woodWall = configuration.getDefaultWoodWall();
        const updatedWall = {...wall, woodWall, elementWall: updatedElementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleElementOuterColorChange = useCallback((elementOuterColor: string) => {
        if (wall.elementWall.outerColor === elementOuterColor) {
            return;
        }

        const updatedElementWall = {...wall.elementWall, outerColor: elementOuterColor};
        const woodWall = configuration.getDefaultWoodWall();
        const updatedWall = {...wall, woodWall, elementWall: updatedElementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleElement1Change = useCallback((element: string) => {
        if (wall.elementWall.elements.length > 0 && wall.elementWall.elements[0] === element) {
            return;
        }

        const updatedElementWall = {...wall.elementWall, elements: [element]};
        const woodWall = configuration.getDefaultWoodWall();
        const updatedWall = {...wall, woodWall, elementWall: updatedElementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
        setWoodenWall(false);
    }, [wall]);

    const handleElement2Change = useCallback((element: string) => {
        if (wall.elementWall.elements.length > 1 && wall.elementWall.elements[1] === element) {
            return;
        }

        const elements = [...wall.elementWall.elements];

        if (elements.length > 1) {
            elements[1] = element;
        } else {
            elements.push(element);
        }

        const updatedElementWall = {...wall.elementWall, elements};
        const woodWall = configuration.getDefaultWoodWall();
        const updatedWall = {...wall, woodWall, elementWall: updatedElementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleElement3Change = useCallback((element: string) => {
        if (wall.elementWall.elements.length > 2 && wall.elementWall.elements[2] === element) {
            return;
        }

        const elements = [...wall.elementWall.elements];

        if (elements.length > 2) {
            elements[2] = element;
        } else {
            elements.push(element);
        }

        const updatedElementWall = {...wall.elementWall, elements};
        const woodWall = configuration.getDefaultWoodWall();
        const updatedWall = {...wall, woodWall, elementWall: updatedElementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleElementSideChange = useCallback((side: string) => {
        if (wall.elementWall.side === side) {
            return;
        }

        // @ts-ignore
        const elementWall: ElementWall = {...wall.elementWall, side};
        const woodWall = configuration.getDefaultWoodWall();
        const updatedWall = {...wall, woodWall, elementWall, slidingGlassWall: 'empty'};
        addWall(updatedWall);
        setWall(updatedWall);
    }, [wall]);

    const handleSlidingGlassWallChange = (slidingGlassWallEnable: boolean) => {
        let slidingGlassWall = 'empty';

        if (slidingGlassWallEnable) {
            slidingGlassWall = configurator.getSlidingGlassDoor(wall);
        }

        const updatedWall: Wall = {
            id: wall.id,
            wallId: 0,
            position: wall.position,
            inner: wall.inner,
            slidingGlassWall,
            woodWall: configuration.getDefaultWoodWall(),
            elementWall: configuration.getDefaultElementWall(),
        };

        addWall(updatedWall);
        setWall(updatedWall);
        setWoodenWall(false);
        setIndividualElements(false);
    };

    const wallOptionsTabs = [
        {
            id: 1,
            title: 'Houtsoort wand',
            component: <ColorTab key={'wall-wood-type'} parent="walls-tab" wall={wall}
                                 onSetInnerColor={handleInnerColorChange}
                                 onSetOuterColor={handleOuterColorChange}
                                 onSetInnerColorEnable={handleInnerColorEnableChange}/>
        },
        {
            id: 2,
            title: 'Deur en raam',
            component: <DoorWindowTab
                key={'wall-door-window'}
                wall={wall}
                onDoorChange={handleChangeDoor}
                onDoorTurningChange={handleDoorTurningChange}
                onDoorWindowSideChange={handleDoorWindowSideChange}
                onWindowChange={handleChangeWindow}
            />
        },
    ];

    const handleSelectedWall = (newUiWall: UiWall | null) => {
        setSelectedWall(newUiWall);
        const newWall = configuration.findWall(newUiWall);
        setWall(newWall);
    };

    useEffect(() => {
        setActiveWalls(state.walls.map((wall) => wall.id));
    }, [state.walls]);

    return (
        <div className="walls-tab">
            <div className="walls-selector-container">
                <WallSelector onWallSelect={handleSelectedWall} activeWalls={activeWalls}/>
            </div>
            <Option image={`${process.env.PUBLIC_URL}/images/houten-wand.png`} name="Houten wand"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} components={[<HorizontalTabs tabs={wallOptionsTabs} key={'wall-tabs'}/>]}
                    value={woodenWall} onChange={setWoodenWall}/>
            <Option image={`${process.env.PUBLIC_URL}/images/glazen-schuifwand.png`} name="Glazen schuifwand"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99} components={[]} value={wall.slidingGlassWall !== 'empty'}
                    onChange={handleSlidingGlassWallChange}/>
            <Option image={`${process.env.PUBLIC_URL}/images/losse-elementen.png`} name="Losse elementen"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum."
                    price={100.99}
                    components={[
                        <ElementTab wall={wall} onElementSideChange={handleElementSideChange}
                                    onElement1Change={handleElement1Change} onElement2Change={handleElement2Change}
                                    onElement3Change={handleElement3Change} key={'wall-element-tab'}
                                    onSetInnerColor={handleElementInnerColorChange}
                                    onSetOuterColor={handleElementOuterColorChange}
                                    onSetInnerColorEnable={handleElementInnerEnableChange}
                        />
                    ]}
                    value={individualElements}
                    onChange={setIndividualElements}/>
        </div>
    );
};

export default WallsTab;