import {Dimensions, Gutter, Parapet, Roof, Wall, Configuration, WoodWall, ElementWall} from "src/types/configuration";
import {UiWall} from "src/components/Tabs/WallsTab/components/WallSelector";
import ConfiguratorService from "src/services/configuratorService";
import {getWallPosition} from "src/helpers/walls";

class ConfigurationService {
    private static instance: ConfigurationService;
    private configurator?: ConfiguratorService;
    private roofType?: string;
    private dimensions?: Dimensions;
    private parapet?: Parapet;
    private roof?: Roof;
    private gutter?: Gutter;
    private walls?: Wall[];
    private storages: { left: boolean, right: boolean } = {left: false, right: false};

    constructor(configuration: Configuration, configuratorService: ConfiguratorService) {
        if (ConfigurationService.instance) {
            return ConfigurationService.instance;
        }

        this.configurator = configuratorService;
        this.roofType = configuration.roofType;
        this.dimensions = configuration.dimensions;
        this.parapet = configuration.parapet;
        this.gutter = configuration.gutter;
        this.roof = configuration.roof;
        this.walls = configuration.walls;
        this.storages = configuration.storages;

        ConfigurationService.instance = this;
    }

    public getRoofType(): string {
        if (!this.roofType) {
            throw new Error("RoofType is not set yet.");
        }

        return this.roofType;
    }

    public getDimensions(): Dimensions {
        if (!this.dimensions) {
            throw new Error("Dimensions are not set yet.");
        }

        return this.dimensions as Dimensions;
    }

    public getParapet(): Parapet {
        if (!this.parapet) {
            throw new Error("Parapet is not set yet.");
        }

        return this.parapet as Parapet;
    }

    public getGutter(): Gutter {
        if (!this.gutter) {
            throw new Error("Gutter is not set yet.");
        }

        return this.gutter as Gutter;
    }

    public getRoof(): Roof {
        if (!this.roof) {
            throw new Error("Roof is not set yet.");
        }

        return this.roof as Roof;
    }

    public getWalls(): Wall[] {
        return this.walls ?? [];
    }

    public getStorages() {
        return this.storages;
    }

    public getDefaultWoodWall(): WoodWall {
        return {
            side: 'left',
            innerEnable: false,
            innerColor: 'empty',
            outerColor: 'empty',
            rotate: false,
            window: 'empty',
            door: 'empty',
            doorSide: 'left'
        };
    }

    public getDefaultElementWall(): ElementWall {
        return {
            side: 'left',
            innerEnable: false,
            innerColor: 'empty',
            outerColor: 'empty',
            elements: []
        };
    }

    public getDefaultWall(wallId: string, inner: boolean = false): Wall {
        return {
            id: wallId,
            wallId: 0,
            position: getWallPosition(wallId),
            inner: inner,
            slidingGlassWall: 'empty',
            woodWall: this.getDefaultWoodWall(),
            elementWall: this.getDefaultElementWall(),
        };
    }

    public setRoofType(roofType: string): void {
        this.roofType = roofType;
    }

    public setDimensions(dimensions: Dimensions): void {
        this.dimensions = dimensions;
    }

    public setParapet(parapet: Parapet): void {
        this.parapet = parapet;
    }

    public setRoof(roof: Roof): void {
        this.roof = roof;
    }

    public setGutter(gutter: Gutter): void {
        this.gutter = gutter;
    }

    public setWalls(walls: Wall[]): void {
        this.walls = walls;
    }

    public setStorages(storages: { left: boolean; right: boolean; }): void {
        this.storages = storages;
    }

    public findWall(selectedWall: UiWall | null) {
        if (!selectedWall) {
            return this.getDefaultWall('');
        }

        const foundWall = this.getWalls().find(wall => wall.id === selectedWall.id);

        return foundWall ? {...foundWall} : this.getDefaultWall(selectedWall.id, selectedWall.inner);
    }

    public getConfiguration() {
        return {
            dimensions: this.dimensions,
            roof: this.roof,
            parapet: this.parapet,
            gutter: this.gutter,
            roofType: this.roofType,
            walls: this.walls,
            storages: this.storages,
        }
    }
}

export default ConfigurationService;