import React from 'react';
import './MaterialsListTab.css';
import DataTable from "../../DataTable/DataTable";

const MaterialsListTab: React.FC = () => {
    return (
        <div className="materiaallijst-tab">
            <DataTable/>
        </div>
    );
};

export default MaterialsListTab;