import React from 'react';
import RadioBlocks from "src/components/Input/RadioBlocks/RadioBlocks";

const colors = [
    {
        id: 1,
        name: 'Douglas Zweeds-rabat',
        imageUrl: `${process.env.PUBLIC_URL}/images/buitenwand-1.png`,
        color: '',
        value: 'wall_potdeksel_2200x1000',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 2,
        name: 'Douglas wisselsponning',
        imageUrl: `${process.env.PUBLIC_URL}/images/buitenwand-2.png`,
        color: '',
        value: 'wisselsponning',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 3,
        name: 'Douglas Triple profiel',
        imageUrl: `${process.env.PUBLIC_URL}/images/buitenwand-3.png`,
        color: '',
        value: 'triple_profiel',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 4,
        name: 'Douglas Zweeds-rabat zwart gespoten',
        imageUrl: `${process.env.PUBLIC_URL}/images/buitenwand-4.png`,
        color: '',
        value: 'wall_potdeksel_2200x1000_black',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 5,
        name: 'Douglas Zweeds-rabat zwart geïmpregneerd',
        imageUrl: `${process.env.PUBLIC_URL}/images/buitenwand-5.png`,
        color: '',
        value: 'wisselsponning_black',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
    {
        id: 6,
        name: 'Douglas Triple profiel zwart gespoten',
        imageUrl: `${process.env.PUBLIC_URL}/images/buitenwand-6.png`,
        color: '',
        value: 'triple_profiel_black',
        price: 100.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur eros vel orci malesuada, sit amet suscipit mi aliquam. Praesent tincidunt velit at sem placerat, ut luctus enim gravida. Nullam volutpat urna a risus consectetur, et lacinia odio fermentum.'
    },
];

interface ColorSelectProps {
    value: string;
    onChange: (value: string) => void;
}

const ColorSelect: React.FC<ColorSelectProps> = ({value, onChange}) => {
    return (
        <>
            <RadioBlocks blocks={colors} value={value} onChange={onChange}/>
        </>
    )
};

export default ColorSelect;