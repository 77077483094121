import React, {useState, useEffect, ChangeEvent} from 'react';
import './SimpleRadioButtons.css';

interface SimpleRadioButtonValue {
    value: string;
    label: string;
}

interface SimpleRadioButtonsProps {
    name?: string;
    value: string;
    values: SimpleRadioButtonValue[];
    onChange: (value: string) => void;
    ariaLabelledBy?: string;
}

const SimpleRadioButtons: React.FC<SimpleRadioButtonsProps> = ({name, value, values, onChange, ariaLabelledBy}) => {
    return (
        <div className="simple-radio-buttons" role="radiogroup" aria-labelledby={ariaLabelledBy}>
            {values.map(({label, value: itemValue}, index) => {
                const itemId = `${name}-${index}`;
                return (
                    <div key={itemValue} className="simple-radio-buttons__option">
                        <input
                            className="simple-radio-buttons__input"
                            type="radio"
                            id={itemId}
                            name={name}
                            value={itemValue}
                            checked={itemValue === value}
                            onChange={(event) => onChange(event.target.value)}
                        />
                        <label htmlFor={itemId} className="simple-radio-buttons__label">
                            {label}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default SimpleRadioButtons;